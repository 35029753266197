export default {
  setToken(state, token) {
    state.token = token;
  },
  setLang(state, lang) {
    state.language = lang;
  },
  setRequisitions(state, order) {
    state.requisitions.push(order);
  },
  setRequisition(state, order) {
    state.requisition = order;
  },
  setCustomer(state, user) {
    state.customer = user;
    var data = localStorage.getItem("stockCustomer");
    if (data && data != "undefined") {
      data = decodeURIComponent(data);
      data = JSON.parse(data);
      if (data.customer.id != user.customer.id) {
        state.cart.products = [];
      }
    }
    var result = encodeURIComponent(JSON.stringify(state.customer));
    localStorage.setItem("stockCustomer", result);
  },
  addToCart(state, data) {
    state.cart.products.push(data);
    var result = encodeURIComponent(JSON.stringify(state.cart));
    localStorage.setItem("stockAgent_cart", result);
    // console.log(state.cart)
  },
  updateCart(state, data) {
    state.cart.products = [];
    state.cart.products = data;
    var result = encodeURIComponent(JSON.stringify(state.cart));
    localStorage.setItem("stockAgent_cart", result);
    // console.log(state.cart)
  },
  getUser(state) {
    var data = localStorage.getItem("stockAgent");
    if (!data) {
      state.user = null;
      state.token = null;
      // window.location.href="/login";
    } else {
      data = decodeURIComponent(data);
      data = JSON.parse(data);
      // console.log(data)
      state.user = data;
      state.token = data.token;

      var result = encodeURIComponent(JSON.stringify(data));
      localStorage.setItem("stockAgent", result);
    }
  },
  updateCartUnit(state, data) {
    console.log(data);
    state.cart.products.forEach((product) => {
      if (product.product.productCode == data.code) {
        product.prodUnitId = data.unit;
        product.cartUnitName = data.name;
        // console.log(state.cart.products)
        // console.log(product)
      }
    });
  },
  removeFromCart(state, id) {
    state.cart.products.forEach((prod, index) => {
      if (prod.product.productCode == id) {
        prod.quantity = 0;
        state.cart.products.splice(index, 1);
        var result = encodeURIComponent(JSON.stringify(state.cart));
        localStorage.setItem("stockbooster_cart", result);
      }
    });
  },
  setUser(state, data) {
    state.user = data;
    state.token = data.token;
    // console.log(state.user)
    var result = encodeURIComponent(JSON.stringify(data));
    localStorage.setItem("stockAgent", result);
  },
  logout(state) {
    window.localStorage.removeItem("stockAgent");
    state.user = null;
    state.token = null;
    window.location.href = "/login";
  },
};
