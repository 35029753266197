<template>
  <div class="page-content" v-if="loaded">
    <div class="container">
      <div class="content-area card">
        <div class="card-innr card-innr-fix">
          <div class="card-head">
            <h6 class="card-title">Agent Info</h6>
          </div>
          <div class="gaps-1x"></div>
          <!-- .gaps -->
          <form @submit.prevent="update()">
            <div class="row">
              <div class="col-md-6">
                <div class="input-item input-with-label">
                  <label class="input-item-label">{{ $t("fname") }}</label>
                  <input
                    class="input-bordered"
                    type="text"
                    v-model="agent.agent.firstname"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-item input-with-label">
                  <label class="input-item-label">{{ $t("lname") }}</label>
                  <input
                    class="input-bordered"
                    type="text"
                    v-model="agent.agent.lastname"
                    disabled
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-item input-with-label">
                  <label class="input-item-label">{{ $t("email") }}</label>
                  <input
                    class="input-bordered"
                    type="text"
                    v-model="agent.agent.email"
                    disabled
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-item input-with-label">
                  <label class="input-item-label">{{
                    $t("phone_number")
                  }}</label>
                  <input
                    class="input-bordered"
                    type="text"
                    v-model="agent.agent.phoneNumber"
                    disabled
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-item input-with-label">
                  <label class="input-item-label">{{ $t("territory") }}</label>
                  <input
                    class="input-bordered"
                    type="text"
                    v-model="agent.territoryName"
                    disabled
                  />
                </div>
              </div>

              <!-- <div class="col-12">
                <button class="btn btn-primary" type="submit">
                  <span v-if="!loading">{{ $t("update") }}</span>
                  <span v-else>{{ $t("loading") }}</span>
                </button>
              </div> -->
            </div>
          </form>
        </div>
        <!-- .card-innr -->
      </div>
      <div>
        <h6 class="card-title mb-3">Change Password</h6>
      </div>
      <div class="content-area card">
        <div class="card-innr card-innr-fix">
          <div class="card-head"></div>
          <div>
            <form @submit.prevent="changePassword()">
              <div class="form-group mb-2">
                <label for="">{{ $t("o_password") }}</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="password.currentPassword"
                />
              </div>
              <div class="form-group mb-2">
                <label for="">{{ $t("n_password") }}</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="password.newPassword"
                />
              </div>
              <div class="form-group mb-2">
                <label for="">{{ $t("cn_password") }}</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="password.password_confirmation"
                />
              </div>
              <button type="submit" :disabled="loading" class="btn btn-primary">
                <span v-if="!loading">{{ $t("c_password") }}</span>
                <span v-else> {{ $t("loading") }}</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
// import Password from "";
import { mapState } from "vuex";
export default {
  data() {
    return {
      customer: {},
      loading: false,
      loaded: false,
      territories: [],
      agent: {},
      password: {
        currentPassword: "",
        newPassword: "",
        password_confirmation: "",
      },
    };
  },
  computed: {
    ...mapState({
      user: (context) => context.user,
    }),
  },
  methods: {
    changePassword() {
      if (this.password.newPassword !== this.password.password_confirmation) {
        window.ToasterAlert(
          "error",
          "Password and Confirm password does not match"
        );
        return false;
      }
      this.loading = true;
      this.$store
        .dispatch("post", {
          endpoint: "services/app/Profile/ChangePassword",
          details: this.password,
        })
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          window.ToasterAlert("success", "Password changed successfully");
          // setTimeout(() => {
          //   this.$store.commit("logout");
          // }, 2000);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getAgent() {
      this.$store
        .dispatch(
          "get",
          "services/app/Agents/GetAgentForView?Id=" + this.user.agent.id
        )
        .then((resp) => {
          console.log(resp);
          if (resp.data.success) {
            this.loaded = true;
            this.agent = resp.data.result;
            // console.log(this.customer)
          }
        });
    },
  },
  created() {
    console.log(this.user);
    this.getAgent();
  },
};
</script>

<style>
.vs__dropdown-toggle {
  height: 42px;
}
</style>
