<template>
  <div class="page-content">
    <div class="container">
      <div
        class="pc-header d-flex justify-content-between align-items-center mb-2"
      >
        <h5 class="card-title font-weight-bold mb-0">Workflows</h5>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card content-area">
            <div class="card-innr">
              <div v-if="requisitions.length > 0">
                <div class="d-none d-md-block">
                  <div class="table-responsive">
                    <table class="data-table dt-init kyc-list table-striped">
                      <thead>
                        <tr class="data-item data-head">
                          <th class="table-col text-left">{{ $t("Date") }}</th>
                          <th class="table-col">Request Number</th>
                          <th class="table-col">Requester</th>
                          <th class="table-col">{{ $t("action") }}</th>
                          <th class="table-col">Decision</th>
                          <th class="table-col dt-status">
                            {{ $t("status") }}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="data-item"
                          v-for="(order, index) in requisitions"
                          :key="index"
                        >
                          <!-- <td class="table-col">
                            <span class="sub sub-s2">{{
                              order.orderNumber
                            }}</span>
                          </td> -->
                          <td class="table-col text-left">
                            <span class="sub sub-s2">
                              {{
                                new Date(
                                  order.stockRequest.stockRequest.requestDate
                                ).toDateString()
                              }}
                            </span>
                          </td>
                          <td class="table-col">
                            <span class="sub sub-s2">{{
                              order.stockRequest.stockRequest.requestNumber
                            }}</span>
                          </td>
                          <td class="table-col">
                            <span class="sub sub-s2">{{
                              order.stockRequest.requestedByName
                            }}</span>
                          </td>
                          <td class="table-col">
                            <span
                              class="sub sub-s2"
                              v-if="
                                order.stockRequestWorkflow.action == 0 ||
                                order.stockRequestWorkflow.action == 4 ||
                                order.stockRequestWorkflow.action == 5
                              "
                              >Approve/Reject</span
                            >
                            <span
                              class="sub sub-s2"
                              v-if="order.stockRequestWorkflow.action == 1"
                              >Issue</span
                            >
                            <span
                              class="sub sub-s2"
                              v-if="order.stockRequestWorkflow.action == 2"
                              >Reconcile</span
                            >
                            <span
                              class="sub sub-s2"
                              v-if="order.stockRequestWorkflow.action == 3"
                              >Close</span
                            >
                          </td>

                          <td class="table-col">
                            <div
                              class="table-flex"
                              v-if="order.stockRequestWorkflow.decision == 0"
                            >
                              <div class="dot dot-primary"></div>
                              <span class="sub sub-s2 dot-t-primary"
                                >Draft</span
                              >
                            </div>

                            <div
                              class="table-flex"
                              v-if="order.stockRequestWorkflow.decision == 1"
                            >
                              <div class="dot dot-success"></div>
                              <span class="sub sub-s2 dot-t-success"
                                >Submitted</span
                              >
                            </div>
                            <div
                              class="table-flex"
                              v-if="order.stockRequestWorkflow.decision == 2"
                            >
                              <div class="dot dot-danger"></div>
                              <span class="sub sub-s2 dot-t-danger"
                                >Rejected</span
                              >
                            </div>
                            <div
                              class="table-flex"
                              v-if="order.stockRequestWorkflow.decision == 3"
                            >
                              <div class="dot dot-success"></div>
                              <span class="sub sub-s2 dot-t-success"
                                >Approved</span
                              >
                            </div>
                            <div
                              class="table-flex"
                              v-if="order.stockRequestWorkflow.decision == 4"
                            >
                              <div class="dot dot-secondary"></div>
                              <span class="sub sub-s2 dot-t-secondary"
                                >Issued</span
                              >
                            </div>
                            <div
                              class="table-flex"
                              v-if="order.stockRequestWorkflow.decision == 5"
                            >
                              <div class="dot dot-success"></div>
                              <span class="sub sub-s2 dot-t-success"
                                >Reconciled</span
                              >
                            </div>
                            <div
                              class="table-flex"
                              v-if="order.stockRequestWorkflow.decision == 6"
                            >
                              <div class="dot dot-success"></div>
                              <span class="sub sub-s2 dot-t-success"
                                >Closed</span
                              >
                            </div>
                            <div
                              class="table-flex"
                              v-if="order.stockRequestWorkflow.decision == 7"
                            >
                              <div class="dot dot-success"></div>
                              <span class="sub sub-s2 dot-t-success"
                                >Approved2</span
                              >
                            </div>
                            <div
                              class="table-flex"
                              v-if="order.stockRequestWorkflow.decision == 8"
                            >
                              <div class="dot dot-danger"></div>
                              <span class="sub sub-s2 dot-t-danger"
                                >Rejected2</span
                              >
                            </div>
                            <div
                              class="table-flex"
                              v-if="order.stockRequestWorkflow.decision == 10"
                            >
                              <div class="dot dot-success"></div>
                              <span class="sub sub-s2 dot-t-success"
                                >Approved3</span
                              >
                            </div>
                            <div
                              class="table-flex"
                              v-if="order.stockRequestWorkflow.decision == 11"
                            >
                              <div class="dot dot-danger"></div>
                              <span class="sub sub-s2 dot-t-danger"
                                >Rejected3</span
                              >
                            </div>
                            <div
                              class="table-flex"
                              v-if="order.stockRequestWorkflow.decision == 9"
                            >
                              <div class="dot dot-danger"></div>
                              <span class="sub sub-s2 dot-t-danger"
                                >Cancelled</span
                              >
                            </div>
                          </td>
                          <td class="table-col">
                            <span
                              class="sub sub-s2"
                              v-if="order.stockRequestWorkflow.status == 0"
                              >Pending</span
                            >
                            <span
                              class="sub sub-s2"
                              v-if="order.stockRequestWorkflow.status == 1"
                              >Completed</span
                            >
                          </td>
                          <td class="table-col text-center table-action">
                            <router-link
                              :to="{
                                name: 'workflow',
                                params: {
                                  id: order.stockRequestWorkflow.id,
                                },
                              }"
                              class="eye"
                            >
                              <em class="ti ti-eye"></em>
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="m-recent-orders d-md-none d-block">
                  <div
                    class="mro-item"
                    v-for="(order, index) in requisitions"
                    :key="index"
                  >
                    <router-link
                      :to="{
                        name: 'stockRequest',
                        params: { id: order.stockRequest.id },
                      }"
                      class="d-flex justify-content-between"
                    >
                      <div class="mro-left">
                        <div class="mrol-wrapper d-flex">
                          <div class="mrol-icon pending">
                            <i class="fa fa-arrow-up"></i>
                          </div>
                          <!-- <div
                            class="mrol-icon"
                            :class="
                              order.orderStatus == 1 ? 'success' : 'pending'
                            "
                          >
                            <i class="fa fa-arrow-up"></i>
                          </div> -->

                          <div class="mrol-main ml-2">
                            <p>
                              {{ user.agent.firstname }}
                              {{ user.agent.lastname }}
                            </p>
                            <span class="order-id">ORDER ID: 3456789</span>
                          </div>
                        </div>
                      </div>
                      <div class="mro-right text-right">
                        <span>{{ $t("pending") }}</span>
                        <!-- <p>
                          {{ user.country.currencySymbol }}
                          {{ formatPrice(order.orderTotalPrice) }}
                        </p> -->
                        <!-- <span>{{
                          order.orderStatus == 0
                            ? $t("pending")
                            : order.orderStatus == 1
                            ? "approaved"
                            : $t("cancelled")
                        }}</span> -->
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div
                class="text-center text-dark font-weight-bold alert alert-info"
                v-else
              >
                <p>No recent orders</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState({
        user: (context) => context.user,
      }),
    },
    data() {
      return {
        requisitions: [],
      };
    },
    methods: {
      getRequisitions() {
        this.$store
          .dispatch(
            "get",
            "services/app/StockRequests/GetMyWorkflows?agentid=" +
              this.user.agent.id
          )
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.requisitions = resp.data.result;
            }
          })
          .catch((error) => {
            this.$store.dispatch("handleError", error);
          });
      },
    },
    created() {
      this.getRequisitions();
    },
  };
</script>

<style scoped>
  .token-info-head {
    font-size: 28px;
  }
  .table-action .btn {
    min-width: 0;
    padding: 2px 10px;
  }
  .eye {
    line-height: 1;
  }
</style>
