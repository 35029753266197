<template>
  <div class="page-content" v-if="loaded">
    <div class="container">
      <div class="input-item input-with-label">
        <div class="relative">
          <span class="input-icon input-icon-left"
            ><em class="fa fa-search"></em
          ></span>
          <input
            class="input-bordered"
            v-model="search"
            type="text"
            :placeholder="$t('search') + '...'"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mobile-ds">
          <div class="table-responsive d-none d-md-block">
            <table class="data-table dt-init kyc-list" cellSpacing="0">
              <thead>
                <tr class="data-item data-head">
                  <th class="table-col">{{ $t("name") }}</th>
                  <th class="table-col">{{ $t("urno") }}</th>
                  <th class="table-col">{{ $t("outlet_name") }}</th>
                  <th class="table-col">{{ $t("phone_number") }}</th>
                  <th class="table-col">{{ $t("address") }}</th>
                  <th class="table-col">{{ $t("l_point") }}</th>
                  <th class="table-col">{{ $t("action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="data-item"
                  v-for="item in filteredList"
                  :key="item.customer.id"
                >
                  <td class="table-col">
                    <span class="sub sub-s2"
                      >{{ item.customer.firstName }}
                      {{ item.customer.lastName }}</span
                    >
                  </td>
                  <td class="table-col">
                    <span class="sub sub-s2">
                      {{ item.customer.urno }}
                    </span>
                  </td>
                  <td class="table-col">
                    <span class="sub sub-s2">
                      {{ item.customer.outletName }}
                    </span>
                  </td>

                  <td class="table-col">
                    <span class="sub sub-s2">{{
                      item.customer.phoneNumber
                    }}</span>
                  </td>
                  <td class="table-col">
                    <span class="sub sub-s2">{{
                      item.customer.outletAddress
                    }}</span>
                  </td>

                  <td class="table-col">
                    <span class="sub sub-s2">{{
                      item.customer.loyaltyPoint
                    }}</span>
                  </td>
                  <td class="table-col text-center actions">
                    <div class="d-flex align-items-center">
                      <a
                        href="#"
                        @click="newOrder(item)"
                        class="f-add"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="New Order"
                        ><em class="ti ti-plus"></em
                      ></a>
                      <router-link
                        :to="'customer/' + item.customer.id + '/edit'"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Edit Customer"
                        ><em class="ti ti-pencil-alt"></em
                      ></router-link>
                      <router-link
                        :to="'customer/' + item.customer.id"
                        class="eye"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="View Customer"
                        ><em class="ti ti-eye"></em
                      ></router-link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="m-recent-orders d-md-none d-block">
            <div
              class="mro-item px-3"
              v-for="item in filteredList"
              :key="item.customer.id"
            >
              <router-link
                :to="'customer/' + item.customer.id"
                class="d-flex justify-content-between"
              >
                <div class="mro-left">
                  <div class="mrol-wrapper d-flex">
                    <div class="mrol-icon">
                      <span
                        :style="
                          'background-color:#' +
                          Math.floor(Math.random() * 16777215).toString(16)
                        "
                      >
                        {{
                          item.customer.firstName &&
                          item.customer.firstName.length > 0
                            ? item.customer.firstName[0]
                            : ""
                        }}
                        {{
                          item.customer.lastName &&
                          item.customer.lastName.length > 0
                            ? item.customer.lastName[0]
                            : ""
                        }}
                      </span>
                    </div>

                    <div class="mrol-main ml-2">
                      <p>
                        {{ item.customer.firstName }}
                        {{ item.customer.lastName }}
                      </p>
                      <span class="order-id">{{
                        item.customer.outletName
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="mro-right text-right">
                  <div>
                    <p class="price-pending">
                      {{ item.customer.loyaltyPoint }} <span>pts</span>
                    </p>
                    <span>{{ item.customer.phoneNumber }}</span>
                  </div>
                  <div class="ml-3 angle-right">
                    <i class="ti ti-angle-right"></i>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- .container -->
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState({
        user: (context) => context.user,
      }),
      filteredList() {
        return this.customers.filter((customer) => {
          return (
            customer.customer.firstName
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            customer.customer.lastName
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            customer.customer.phoneNumber
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            customer.customer.outletName
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            customer.customer.region
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            customer.customer.lga
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            customer.customer.outletAddress
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            customer.customer.retailChannel
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            customer.customer.landmark
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        });
      },
    },
    data() {
      return {
        customers: [],
        search: "",
        loaded: false,
      };
    },
    methods: {
      newOrder(user) {
        // console.log(user);
        this.$store.commit("setCustomer", user);
        this.$router.push("store");
      },
      getCustomers() {
        this.$store
          .dispatch(
            "get",
            "services/app/Agents/GetRoutePlan?agentid=" + this.user.agent.id
          )
          .then((resp) => {
            if (resp.data.success) {
              this.loaded = true;
              this.customers = resp.data.result;
              console.log(this.customers);
            }
          });
      },
    },
    created() {
      this.getCustomers();
    },
  };
</script>

<style scoped>
  .price-pending span {
    font-size: 13px;
  }
  thead th {
    background-color: #f9f9f9;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  .table-col,
  .data-item .sub-s2 {
    color: rgba(0, 0, 0, 0.6);
  }
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
  }
  .mro-right {
    display: flex;
    align-items: center;
  }
  .angle-right {
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
  }
  .mrol-icon span {
    border: 1px solid transparent;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  .mro-item {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-top: 0;
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .data-details-head {
    text-transform: uppercase;
    padding: 5px 7px 0px;
  }
  .data-details-des {
    padding: 2px 7px 3px;
  }
  .f-add {
    border: 1px solid transparent;
    background: rgba(17, 42, 99, 0.8);
    color: #fff !important;
  }
  .actions a {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: rgba(17, 42, 99, 0.8);
    font-size: 16px;
    font-weight: bold;
  }
  .actions .eye {
    border: 1px solid transparent;
    background: rgba(17, 42, 99, 0.1);
    border-radius: 4px;
  }
  @media (max-width: 576px) {
    .mobile-ds {
      padding: 0;
    }
  }
</style>
