<template>
  <span v-if="channel == 1"> Web </span>
  <span v-else-if="channel == 2"> USSD </span>
  <span v-else-if="channel == 3"> Telegram </span>
  <span v-else-if="channel == 4"> ReODA </span>
  <span v-else-if="channel == 5"> SOQ </span>
  <span v-else-if="channel == 6"> Portal </span>
  <span v-else-if="channel == 7"> ContactCenter </span>
</template>
<script>
  export default {
    props: {
      channel: {},
    },
  };
</script>
