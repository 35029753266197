export default {
  approved: "Approuvée",
  t_line_items: "Total des éléments de campagne",
  confirm_o: "Confirmer la commande",
  confirm_delivery: "Confirmer la livraison",
  territory: "Territoire",
  profile: "Profil",
  c_password: "Changer le mot de passe",
  o_password: "Mot de passe actuel",
  n_password: "Nouveau mot de passe",
  cn_password: "Confirmer le nouveau mot de passe",
  stock_r: "Demande de stock",
  requestor: "Demandeur",
  requisitions: "Réquisitions",
  newsfeed: "Flux",
  requisition: "Réquisition",
  create_request: "Créer une demande",
  request_summary: "Résumé de la demande",
  request_details: "Détails de la demande",
  n_items: "Articles",
  order_val: "Validation de la commande",
  order_no: "Numéro de commande",
  summary: "Récapitulatif de la commande",
  edit: "Éditer",
  back: "Arrière",
  action: "Action",
  up_order: "Mise à jour de la commande",
  e_order: "Modifier la commande",
  add_new_prod: "Ajouter un nouveau produit",
  products: "Des produits",
  quantity: "Quantité",
  add_prod: "Ajouter un produit",
  fname: "Prénom",
  lname: "Nom de famille",
  email: "E-mail",
  outlet_name: "Nom du point de vente",
  journey_plan: "Plan de voyage",
  update: "Mettre à jour",
  customer_name: "Nom du client",
  phone_number: "Numéro de téléphone",
  urno: "URNO",
  day: "Jour",
  validated: "Validé",
  order_n: "Numéro de commande",
  order_id: "Numéro de commande",
  login: "Connexion",
  loading: "Chargement...",
  username: "Nom d'utilisateur",
  password: "le mot de passe",
  cpsw: "Changer le mot de passe",
  orders: "Commandes",
  dashboard: "Tableau de bord",
  customers: "Clients",
  customer: "Client",
  welcome: "Bienvenue !",
  logout: "Déconnexion",
  new_order: "Nouvelle commande",
  pending: "En attente",
  p_orders: "Commandes en attente",
  p_order: "Commande en cours",
  ds_order: "Commande livrée",
  cs_order: "Commande annulée",
  d_status: "Statut de livraison",
  c_status: "Statut de confirmation",
  received: "Reçu",
  NotRecieved: "Non reçu",
  dd_orders: "Commandes livrées",
  r_orders: "Dernières commandes",
  place_order: "Passer la commande",
  v_all: "Tout afficher",
  id: "ID",
  client: "client",
  price: "prix",
  time: "heure",
  status: "statut",
  name: "nom",
  phone: "téléphone",
  f_txt1:
    "À des fins commerciales. Ne pas diffuser ou distribuer au grand public.",
  f_c: "TRADERPADi - Tous droits réservés.",
  payment: "paiement",
  delivered: "livré",
  cancelled: "annulé",
  o_details: "détails de la commande",
  o_status: "Statut de la commande",
  o_pstats: "Statut de paiement de la commande",
  p_status: "Statut de paiement",
  p_method: "Mode de paiement",
  to_quant: "Quantité totale de commande",
  o_date: "Date de commande",
  total: "Totale",
  s_n: "S/N",
  product: "produit",
  unit: "unité",
  qty: "quantité",
  d_order: "Livrer la commande",
  c_order: "annuler la commande",
  print: "imprimer",
  search: "chercher",
  history: "l'histoire",
  address: "adresse",
  direction: "direction",
  channel: "canal",
  o_channel: "Canal de commande",
  o_for: "Comander pour",
  cash: "argent",
  credit: "crédit",
  pos: "Pos",
  transfer: "transfert",
  subtotal: "Sous-total",
  order: "Ordre",
  paid: "Payé",
  checkout: "Vérifier",
  go_back: "Retour",
  noitem: "Aucun article dans votre panier",
  sub: "Envoi...",
  s_order: "Soumettre la commande",
  c_shop: "Continuer les achats",
  r_order: "Réviser la commande",
  l_point: "Points de fidélité",
};
