<template>
  <div>
    <div class="topbar-wrap move-left" id="topbar-wrap">
      <div class="topbar is-sticky">
        <div class="container">
          <div class="d-flex nav-split align-items-center">
            <ul
              class="topbar-nav d-lg-none justify-content-between w-100"
              @click="toggleSidebar()"
            >
              <li class="topbar-nav-item relative">
                <router-link
                  to="/dashboard"
                  class="lft-sm-logo"
                  id="nav-sm-logo"
                >
                  <img
                    src="@/assets/images/logo.png"
                    alt="logo"
                    class="w-100"
                  />
                </router-link>
              </li>
              <li class="topbar-nav-item relative mx-0">
                <a
                  class="toggle-nav active"
                  href="javascript:void"
                  @click="toggleNav()"
                >
                  <i class="ti ti-menu"></i>
                  <!-- <i class="ti ti-close"></i> -->
                </a>
              </li>
            </ul>
            <ul class="topbar-nav topbar-nav-sm-none">
              <li class="topbar-nav-item relative mr-0">
                <language />
              </li>
              <li class="topbar-nav-item relative">
                <!-- <span class="user-welcome d-none d-lg-inline-block"> {{$t('welcome')}} {{user.firstName}}</span> -->
                <div class="ct-no">
                  <router-link to="checkout">{{
                    cart.products.length
                  }}</router-link>
                </div>
                <router-link class="toggle-tigger user-thumb" to="checkout">
                  <div class="ct-icon">
                    <em class="ti ti-shopping-cart em-font"></em>
                  </div>
                </router-link>
              </li>
              <li class="side-line py-1">
                <a class="toggle-tigger" href="#" @click="toggleDropdown()">
                  <em class="ti ti-user d-lg-none em-font"></em>
                  <div class="d-lg-flex d-none align-items-center">
                    <div class="sl-lft mr-3">
                      <h6 class="mb-0">
                        {{ user.agent.firstName }} {{ user.agent.lastName }}
                      </h6>
                      <span>{{ user.agent.email }}</span>
                      <span
                        class="d-block"
                        style="line-height: 3px; font-size: 11px"
                        >{{ user.territoryName }}</span
                      >
                    </div>
                    <i class="fa fa-chevron-down"></i>
                  </div>
                </a>
                <div
                  :class="active ? 'active' : ''"
                  class="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown"
                >
                  <div class="user-status">
                    <div class="user-status-balance">
                      <small>{{ user.agent.outletName }}</small>
                    </div>
                  </div>
                  <ul class="user-links">
                    <li>
                      <router-link to="orders"
                        ><i class="ti ti-infinite"></i
                        >{{ $t("orders") }}</router-link
                      >
                    </li>
                  </ul>
                  <ul class="user-links bg-light">
                    <li>
                      <a href="#" @click="logout()"
                        ><i class="ti ti-power-off"></i>{{ $t("logout") }}</a
                      >
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="navbar navbar-mobile" id="navbar">
        <div class="nav-con-wrapper">
          <div class="logo-container">
            <router-link class="topbar-logo" to="/dashboard">
              <img src="../../../assets/images/logo.png" alt="logo" />
            </router-link>
            <a class="logo-close" href="javascript:void" @click="closeNav()">
              <i class="ti ti-close"></i>
            </a>
          </div>
          <div class="container">
            <div class="navbar-innr">
              <ul class="navbar-menu w-100">
                <li
                  @click="closeNav()"
                  :class="this.$route.name == 'dashboard' ? 'actived' : ''"
                >
                  <router-link to="/dashboard"
                    ><img src="@/assets/images/c.svg" alt="dashboard" />
                    {{ $t("dashboard") }}
                  </router-link>
                </li>
                <li
                  @click="closeNav()"
                  :class="this.$route.name == 'orders' ? 'actived' : ''"
                >
                  <router-link to="/orders"
                    ><img src="@/assets/images/b.svg" alt="orders" />
                    {{ $t("orders") }}</router-link
                  >
                </li>
                <li
                  @click="closeNav()"
                  :class="this.$route.name == 'customers' ? 'actived' : ''"
                >
                  <router-link to="/customers"
                    ><img src="@/assets/images/d.svg" alt="customers" />{{
                      $t("customers")
                    }}
                  </router-link>
                </li>
                <li
                  @click="closeNav()"
                  :class="this.$route.name == 'journeyPlans' ? 'actived' : ''"
                >
                  <router-link to="/journey-plans"
                    ><img src="@/assets/images/a.svg" alt="journeyPlans" />
                    {{ $t("journey_plan") }}</router-link
                  >
                </li>
                <li
                  @click="closeNav()"
                  class="colp-main"
                  :class="
                    this.$route.name == 'stockRequests' ||
                    this.$route.name == 'workflows' ||
                    this.$route.name == 'workflow' ||
                    this.$route.name == 'stockRequest' ||
                    this.$route.name == 'CreateRequest'
                      ? 'actived'
                      : ''
                  "
                >
                  <a
                    class="myRequests"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    ><i class="ti ti-package"></i> {{ $t("stock_r") }}

                    <i class="ti ti-angle-right cpIcon"></i
                  ></a>
                  <div class="collapse" id="collapseExample">
                    <ul class="pb-3">
                      <li
                        :class="
                          this.$route.name == 'stockRequests' ? 'actived' : ''
                        "
                      >
                        <router-link to="/stock-requests">
                          My Requests</router-link
                        >
                      </li>
                      <li
                        :class="
                          this.$route.name == 'workflows' ? 'actived' : ''
                        "
                      >
                        <router-link to="/workflows"> Workflows</router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  @click="closeNav()"
                  :class="this.$route.name == 'profile' ? 'actived' : ''"
                >
                  <router-link to="/profile"
                    ><i class="ti ti-settings"></i>
                    {{ $t("profile") }}</router-link
                  >
                </li>
                <!-- <li
                  @click="closeNav()"
                  :class="this.$route.name == 'password' ? 'actived' : ''"
                >
                  <router-link to="/password"
                    ><i class="ti ti-settings"></i>
                    {{ $t("cpsw") }}</router-link
                  >
                </li> -->
                <li
                  v-if="user.country.tenantId == 1"
                  @click="closeNav()"
                  :class="this.$route.name == 'newsfeed' ? 'actived' : ''"
                >
                  <router-link to="/newsfeeds"
                    ><i class="ti ti-world"></i>
                    {{ $t("newsfeed") }}</router-link
                  >
                </li>
                <li @click="closeNav()" class="mobile-logout">
                  <a href="javascript:void()" @click="logout()"
                    ><i class="ti ti-power-off"></i>{{ $t("logout") }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-view></router-view>
    <div class="footer-bar bg-white pt-3">
      <div class="text-center">
        <div>{{ $t("f_txt1") }}</div>
      </div>
      <div class="w-100 text-center">
        <img
          v-if="user.country.tenantId == 11"
          src="../../../assets/images/fimg2.jpeg"
          style="width: 80%"
          alt=""
        />
        <img
          v-else-if="user.country.tenantId == 1"
          src="../../../assets/images/fimg3.png"
          style="width: 300px"
          alt=""
        />
        <img
          v-else
          src="../../../assets/images/fimg.png"
          style="width: 100%"
          alt=""
        />
      </div>
      <div class="text-center">
        <div class="copyright-text">
          &copy; {{ new Date().getFullYear() }} {{ $t("f_c") }}
        </div>
        <!-- <language /> -->
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState({
        user: (context) => context.user,
        token: (context) => context.user,
        cart: (context) => context.cart,
      }),
    },
    data() {
      return {
        active: false,
      };
    },
    methods: {
      logout() {
        this.$store.commit("logout");
      },
      closeNav() {
        this.toggleSidebar();
        this.toggleNav();
      },
      toggleSidebar(open = true) {
        if (!open) return false;

        var dm = document.getElementById("navbar");
        dm.classList.add("navbar-mobile");
        var list = dm.classList;
        list.forEach((name) => {
          if (name == "active") {
            dm.classList.remove(name);
          } else {
            dm.classList.add("active");
          }
        });
      },
      toggleNav() {
        window.$("#navTog").toggleClass("navactive");
        window.$(".toggle-nav").toggleClass("active");
        window.$(".topbar-wrap").toggleClass("move-left");
        window.$(".topbar-wrap").toggleClass("move-rgt");
        window.$("#nav-sm-logo").toggleClass("d-none");
      },
      toggleDropdown() {
        this.active = !this.active;
      },
    },
    created() {
      this.$store.commit("getUser");
      if (!this.user) {
        this.$store.commit("logout");
      }
    },
  };
</script>

<style scoped>
  .collapse ul {
    margin-left: 30px;
  }
  .collapse li a {
    width: 100% !important;
    display: block;
    padding: 10px 0 10px 27px;
  }
  .navbar-menu li.actived a li a {
    color: rgba(17, 42, 99, 0.5);
  }
  .cpIcon {
    font-size: 12px !important;
    margin-left: 15px;
  }
  .navbar-menu li a i {
    font-size: 20px;
    margin-right: 16px;
  }
  .logo-close i {
    color: #000000;
    font-size: 13px;
    font-weight: bold;
  }
  /* .toggle-nav.active .ti-menu,  .toggle-nav .ti-close{
        display: none;
    }
    .toggle-nav.active .ti-close, .toggle-nav .ti-menu{
        display: block;
    } */
  .lft-sm-logo {
    width: 100px;
  }
  .sl-lft span {
    font-size: 12px;
  }
  .navbar-menu .colp-main.actived {
    background: transparent;
  }
  .navbar-menu .colp-main.actived a {
    font-weight: lighter;
  }
  .navbar-menu .colp-main.actived .actived a {
    font-weight: bold;
  }

  .navbar-menu .colp-main.actived .myRequests {
    background: rgba(17, 42, 99, 0.1);
    border-radius: 7px;
  }
  .navbar-menu .collapse {
    margin-top: 10px;
  }
  .navbar-menu li.actived {
    background: rgba(17, 42, 99, 0.1);
    border-color: transparent !important;
    border-radius: 7px;
  }
  .navbar-menu li.actived a {
    border-color: transparent !important;
    font-weight: bold;
  }
  .navbar-menu li {
    margin-bottom: 10px;
  }
  .navbar-menu li a img {
    margin-right: 16px;
  }
  .navbar-menu li a {
    font-size: 14px;
    padding-left: 27px;
    color: rgba(17, 42, 99, 0.5);
    font-family: plus_jakarta_displayregular;
  }
  .navbar-menu li.actived a {
    color: #112a63;
  }
  .navbar-menu li a em {
    font-size: 32px;
  }
  .navbar-menu li a:hover {
    background: rgba(17, 42, 99, 0.1);
    border-radius: 7px;
  }
  .nav-split {
    justify-content: space-between;
  }
  .side-line {
    border-left: 2px solid #06143540;
    padding-left: 15px;
    margin-left: 10px;
  }
  .side-line .toggle-tigger {
    color: black;
    display: block;
  }
  .side-line .toggle-tigger span {
    color: rgba(6, 20, 53, 0.7);
  }
  .side-line .toggle-tigger h6 {
    font-weight: bold;
    line-height: 1;
  }
  .move-left {
    margin-left: 0 !important;
  }
  .topbar-wrap {
    transition: all 0.5s;
    box-shadow: 0px 2px 17px rgba(171, 171, 171, 0.1);
  }
  .logo-container {
    height: 72px;
    /* background-color: #112a63; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .em-font {
    font-size: 28px;
  }
  .ct-no {
    transform: translate(35px, -14px);
    font-size: 12px;
    background: red;
    height: 17px;
    width: 17px;
    border-radius: 17px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ct-no a {
    color: #fff;
  }
  .navactive {
    left: 0;
    display: block;
    overflow-y: auto;
  }
  .toggle-nav {
    font-size: 20px;
    color: #112a62;
  }
  .toggle-nav.active .toggle-line:nth-last-of-type(4) {
    transform-origin: top left;
    transform: rotate();
    top: -1px;
  }
  .user-thumb {
    background: #fff;
    display: block;
  }
  .user-thumb:hover {
    color: black;
  }
  .mobile-logout {
    display: none;
  }
  @media (min-width: 991px) {
    .nav-split {
      justify-content: flex-end;
    }
    .logo-container {
      /*height: 178px;*/
    }
  }
  @media (max-width: 576px) {
    .mobile-logout {
      display: block;
    }
    .topbar {
      z-index: 1;
    }
    .navbar-menu li a {
      font-size: 10px;
      padding-left: 17px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .topbar-nav-sm-none {
      display: none;
    }
    .logo-container {
      background-color: #fff;
      justify-content: space-between;
      padding: 0 15px;
    }
    .logo-container .topbar-logo {
      margin-top: 76px;
    }
    .navbar {
      background: rgba(0, 0, 0, 0.5);
      width: 100vw;
    }
    .nav-con-wrapper {
      width: 253px;
      background: #fff;
      height: 100%;
    }
  }
</style>
