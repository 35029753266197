<template>
  <div class="page-content" v-if="loaded">
    <div class="container d-none d-md-block">
      <div class="row mt-2">
        <div :class="cart.products.length > 0 ? 'col-sm-9' : ''" class="col-12">
          <div class="row">
            <div
              :class="
                cart.products.length > 0
                  ? 'col-md-6 col-lg-4'
                  : 'col-md-4 col-lg-3'
              "
              class="col-6"
              v-for="product in products"
              :key="product.id"
            >
              <div class="content-area card products-pg">
                <div class="card-innr">
                  <div class="card-img text-center">
                    <img :src="product.product.imageURL" alt="" />
                  </div>
                  <div class="text-center mt-3">
                    <span class="prod-name">{{
                      product.product.productName
                    }}</span>
                  </div>
                  <!-- <div class="text-center mt-2">
                                        <span class="prod-price">{{user.country.currency}} {{product.product.unitPrice}}</span>
                                    </div> -->
                  <div class="coun-part mt-2">
                    <div class="input-item input-with-label w-100">
                      <div class="select-wrapper">
                        <select
                          :id="product.product.id"
                          :class="product.product.productCode"
                          @change="
                            updateProductUnitId(
                              $event,
                              product.product.productCode
                            )
                          "
                          class="input-bordered required valid s-drop"
                        >
                          <option
                            v-for="unit in units"
                            :key="unit.id"
                            :value="unit.productUnit.id"
                            :selected="
                              unit.productUnit.productUnitName ==
                              product.cartUnitName
                                ? setTrue
                                : !setTrue
                            "
                          >
                            <!-- :selected="unit.productUnit.productUnitName == product.productUnitName?'selected':''" -->
                            {{ unit.productUnit.productUnitName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="input-item input-with-label d-flex align-items-center ic-qty"
                    >
                      <div
                        class="icon-input input-right"
                        @click="minusQty(product.product.productCode)"
                      >
                        <em class="fa fa-minus cal"></em>
                      </div>
                      <input
                        class="input-bordered qty pl-0 border-0"
                        min="1"
                        step=".01"
                        @input="changeQty(product.product.productCode)"
                        :id="product.product.productCode"
                        type="number"
                        :value="product.quantity"
                      />
                      <div
                        class="icon-input input-left"
                        @click="addQty(product.product.productCode)"
                      >
                        <em class="fa fa-plus cal"></em>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="cart.products.length > 0" class="col-sm-3 col-12">
          <div class="row cart-order px-1">
            <div class="col-12">
              <div
                class="cart-order-header d-flex justify-content-between align-items-center py-2 pt-4"
              >
                <h5 class="mb-0">YOUR ORDER</h5>
                <i class="fa fa-times"></i>
              </div>
            </div>
            <div
              class="col-12"
              v-for="(product, index) in cart.products"
              :key="index"
            >
              <div class="cart-product">
                <div class="cart-prod-img">
                  <img :src="product.product.imageURL" />
                </div>
                <div class="cart-prod-details">
                  <div class="cart-prod-content">
                    <h6 class="mb-0">{{ product.product.productName }}</h6>
                    <div class="select-wrapper cart-prod-select">
                      <select
                        :id="'select' + index"
                        @change="
                          updateProductUnitId(
                            $event,
                            product.product.productCode
                          )
                        "
                      >
                        <option
                          v-for="unit in units"
                          :key="unit.id"
                          :value="unit.productUnit.id"
                          :selected="
                            unit.productUnit.productUnitName ==
                            product.cartUnitName
                              ? setTrue
                              : !setTrue
                          "
                        >
                          <!-- :selected="unit.productUnit.productUnitName == product.productUnitName?'selected':''" -->
                          {{ unit.productUnit.productUnitName }}
                        </option>
                      </select>
                    </div>
                    <!-- <h6 class="font-weight-light cart-prod txt-color">QTY: <span :class="'cart'+index">{{product.quantity}} </span> </h6> -->
                    <h5 class="font-weight-bold cpc-price">
                      {{ user.country.currency }}
                      {{
                        formatPrice(product.convertedPrice * product.quantity)
                      }}
                    </h5>
                    <div
                      class="input-item input-with-label d-flex align-items-center ic-qty"
                    >
                      <div
                        class="icon-input input-right"
                        @click="minusQty(product.product.productCode)"
                      >
                        <em class="fa fa-minus cal"></em>
                      </div>
                      <input
                        class="input-bordered qty pl-0"
                        min="1"
                        step=".01"
                        :class="'cart' + index"
                        @input="changeQty(product.product.productCode)"
                        type="number"
                        :value="product.quantity"
                      />
                      <div
                        class="icon-input input-left"
                        @click="
                          addQty(product.product.productCode, 'cart' + index)
                        "
                      >
                        <em class="fa fa-plus cal"></em>
                      </div>
                    </div>
                  </div>
                  <i
                    class="fa fa-trash text-danger mt-3 toggle-tigger"
                    @click="deleteItemFromCart(product.product.productCode)"
                  ></i>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="cart-subtotal d-flex justify-content-between py-3">
                <h5>Subtotal:</h5>
                <h5>
                  {{ user.country.currency }} {{ formatPrice(calculateTotal) }}
                </h5>
              </div>
            </div>
            <div class="col-12">
              <button
                class="btn btn-primary w-100 mb-4"
                @click="checkOut(true)"
              >
                {{ $t("checkout") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-md-none d-block">
      <div class="store-section main-store">
        <div class="store-inner">
          <ul class="store-item-list p-0">
            <li
              class="store-list"
              v-for="product in products"
              :key="product.id"
            >
              <div class="store-item">
                <div class="item-details">
                  <div class="item-img">
                    <img :src="product.product.imageURL" alt="" />
                  </div>
                  <div class="item-name">
                    <h5 class="text-uppercase">
                      {{ product.product.productName }}
                    </h5>
                    <div class="vue-select-group">
                      <!-- <v-select :options="vueData"></v-select> -->
                      <select
                        :id="product.product.id"
                        :class="product.product.productCode"
                        @change="
                          updateProductUnitId(
                            $event,
                            product.product.productCode
                          )
                        "
                        class="input-bordered required valid s-drop"
                      >
                        <option
                          v-for="unit in units"
                          :key="unit.id"
                          :value="unit.productUnit.id"
                          :selected="
                            unit.productUnit.productUnitName ==
                            product.cartUnitName
                              ? setTrue
                              : !setTrue
                          "
                        >
                          <!-- :selected="unit.productUnit.productUnitName == product.productUnitName?'selected':''" -->
                          {{ unit.productUnit.productUnitName }}
                        </option>
                      </select>
                    </div>
                    <span v-if="product.quantity > 0"
                      >{{ $store.state.user.country.currencySymbol
                      }}{{ getSelectedProductPrice(product.product.id) }}</span
                    >
                  </div>
                </div>
                <div class="store-counter">
                  <button
                    type="button"
                    class="btn count-increment"
                    @click="addQty(product.product.productCode)"
                  >
                    <svg
                      width="13"
                      height="14"
                      viewBox="0 0 13 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="0.171875"
                        y1="6.89771"
                        x2="12.4219"
                        y2="6.89771"
                        stroke="white"
                        stroke-width="2.5"
                      />
                      <line
                        x1="6.04688"
                        y1="0.772705"
                        x2="6.04687"
                        y2="13.0227"
                        stroke="white"
                        stroke-width="2.5"
                      />
                    </svg>
                  </button>
                  <input
                    :id="product.product.productCode"
                    step=".01"
                    type="number"
                    min="1"
                    class="count-value"
                    :value="product.quantity"
                    @input="changeQty(product.product.productCode)"
                  />
                  <button
                    type="button"
                    class="btn count-decrement"
                    @click="minusQty(product.product.productCode)"
                  >
                    <svg
                      width="13"
                      height="3"
                      viewBox="0 0 13 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="0.171875"
                        y1="1.34668"
                        x2="12.4219"
                        y2="1.34668"
                        stroke="#061435"
                        stroke-width="2.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <AddItemNotification :data="notification" />
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import AddItemNotification from "./layout/AddItemNotification.vue";
  export default {
    components: { AddItemNotification },
    computed: {
      ...mapState({
        cart: (context) => context.cart,
        user: (context) => context.user,
      }),
      calculateTotal() {
        let total = 0;
        this.cart.products.forEach((element) => {
          let price = element.quantity * element.convertedPrice;
          total += price;
        });
        return total.toFixed(2);
      },
    },
    data() {
      return {
        products: [],
        units: [],
        convert: {},
        setTrue: true,
        notification: {
          active: false,
          price: 0,
          qty: 0,
        },
        loaded: false,
      };
    },
    methods: {
      formatPrice(value) {
        var hold = parseFloat(value).toFixed(2);
        return new Intl.NumberFormat().format(hold);
      },
      getSelectedProductPrice(id) {
        var total = 0;
        this.cart.products.forEach((item) => {
          if (item.product.id == id) {
            total = item.quantity * item.convertedPrice;
          }
        });
        return total.toFixed(2);
      },
      changeQty(id) {
        var num = parseFloat(document.getElementById(id).value);
        if (!isNaN(num)) {
          document.getElementById(id).value = num;
          this.addToCart(id, num);
        }
      },
      updateProductUnitId(event, productCode) {
        this.cart.products.forEach((product) => {
          if (product.product.productCode == productCode) {
            product.prodUnitId = event.target.value;
            product.cartUnitName = this.units.filter(
              (item) => item.productUnit.id == event.target.value
            )[0].productUnit.productUnitName;
          }
        });
        this.checkOut();
        this.$store.commit("updateCart", this.cart.products);
      },
      checkOut(route = false) {
        var chold = this.cart.products;

        chold.forEach((element) => {
          var baseUnitFactor = 0;

          this.units.forEach((item) => {
            if (element.product.productBaseUnitId == item.productUnit.id) {
              baseUnitFactor = item.productUnit.conversionFactor;
              // console.log('baseUnitFactor', baseUnitFactor)
            }
          });
          this.units.forEach((item) => {
            if (element.prodUnitId == item.productUnit.id) {
              element.cartUnitName = item.productUnit.productUnitName;
              var conversionFactor = item.productUnit.conversionFactor;
              element.convertedPrice =
                (parseFloat(baseUnitFactor) / parseFloat(conversionFactor)) *
                element.product.unitPrice;
            }
          });
        });
        this.$store.commit("updateCart", chold);
        this.setNotification();
        if (route) {
          this.$router.push("checkout");
        }
      },
      minusQty(id) {
        var num = document.getElementById(id).value;
        if (num > 0) {
          document.getElementById(id).value = parseInt(num - 1);
          this.removeFromCart(id);
        }
      },
      addQty(id) {
        var num = document.getElementById(id).value;
        document.getElementById(id).value = parseInt(num) + 1;
        this.addToCart(id);
      },
      getProducts() {
        this.$store
          .dispatch(
            "getProduct",
            "services/app/Products/GetAll?MaxResultCount=1000"
          )
          .then((resp) => {
            if (resp.data.success) {
              this.products = resp.data.result.items;
              if (this.cart.products.length > 0) {
                this.products.forEach((prod) => {
                  prod.quantity = 0;
                  this.cart.products.forEach((item) => {
                    if (item.product.productCode == prod.product.productCode) {
                      prod.quantity = item.quantity;
                      setTimeout(() => {
                        var unitId = document.getElementById(item.product.id);
                        unitId.value = item.prodUnitId;
                      }, 1000);

                      // document.getElementsByClassName(item.product.productCode)[0].value = item.prodUnitId
                    }
                  });
                });
              } else {
                this.products.forEach((prod) => {
                  prod.quantity = 0;
                });
              }
              this.loaded = true;
            }
          });
      },
      checkCart(id) {
        var stat = false;
        this.cart.products.forEach((prod) => {
          if (prod.product.productCode == id) {
            prod.quantity = document.getElementById(id).value;
            //check again if user has change the product unit
            prod.prodUnitId = document.getElementsByClassName(id)[0].value;
            stat = true;

            var result = encodeURIComponent(JSON.stringify(this.cart));
            localStorage.setItem("stockbooster_cart", result);
            this.$store.commit("updateCart", this.cart.products);
          }
        });
        return stat;
      },
      deleteItemFromCart(id) {
        this.$store.commit("removeFromCart", id);
      },
      removeFromCart(id) {
        this.cart.products.forEach((prod) => {
          if (prod.product.productCode == id) {
            if (prod.quantity == 1) {
              // this.cart.products.splice(index, 1)
              this.deleteItemFromCart(id);
              document.getElementById(id).value = 0;
            } else {
              prod.quantity -= 1;
            }
            var result = encodeURIComponent(JSON.stringify(this.cart));
            localStorage.setItem("stockbooster_cart", result);
            this.checkOut();
            this.$store.commit("updateCart", this.cart.products);
          }
        });
      },
      addToCart(id, qty = 1) {
        var check = this.checkCart(id);
        if (!check) {
          this.products.forEach((element) => {
            if (element.product.productCode == id) {
              element.quantity = qty;
              var cname = document.getElementsByClassName(id)[0].value;
              element.prodUnitId = cname;
              this.$store.commit("addToCart", element);
            }
          });
        }
        this.checkOut();
      },
      getUnits() {
        this.$store
          .dispatch("getWithTalent", "services/app/ProductUnits/GetAll")
          .then((resp) => {
            if (resp.data.success) {
              this.units = resp.data.result.items;
            }
            // console.log(this.units)
          });
      },
      setNotification() {
        let nqty = this.cart.products.reduce((total, num) => {
          return parseFloat(total) + parseFloat(num.quantity);
        }, 0);
        let nprice = this.cart.products.reduce((total, num) => {
          return (
            parseFloat(total) +
            parseFloat(num.convertedPrice) * parseFloat(num.quantity)
          );
        }, 0);
        this.notification.qty = nqty;
        this.notification.edit = this.edit;
        this.notification.active = true;
        this.notification.price = this.formatPrice(nprice);
      },
    },
    created() {
      this.getUnits();
      this.getProducts();
    },
  };
</script>

<style scoped>
  .prod-name {
    font-size: 13px;
    color: #061435;
  }
  .cart-subtotal h5 {
    color: #061435;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .txt-color {
    color: #6e81a9;
  }
  .cart-prod-content select {
    border: none;
  }
  .cart-prod-content .input-bordered {
    width: 43px;
    border: 1px solid rgba(17, 42, 99, 0.15);
    border-radius: 0;
    padding: 6px 0;
  }
  .cart-prod-content .input-right {
    border: 1px solid rgba(17, 42, 99, 0.15);
    border-right: none;
  }
  .cart-prod-content .input-left {
    border: 1px solid rgba(17, 42, 99, 0.15);
    border-left: none;
  }
  .cart-prod-content .ic-qty {
    border: none;
  }
  .cart-prod-content .icon-input {
    padding: 10px 8px;
  }
  .cpc-price {
    font-size: 17px;
    color: black;
  }
  .cart-prod-select select {
    font-size: 15px;
  }
  .cart-prod-select:after {
    font-size: 15px;
  }
  .cart-prod-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
  }
  .cart-prod-content {
    color: #061435;
  }
  .cart-prod-content h6 {
    font-weight: bold;
  }
  .cart-prod-img {
    width: 55px;
  }
  .cart-product {
    display: flex;
    margin-top: 26px;
    border-bottom: 1px solid rgba(17, 42, 99, 0.15);
    padding-bottom: 20px;
  }
  .cart-order-header h5 {
    color: black;
  }
  .cart-order-header .fa {
    font-size: 21px;
  }
  .cart-order-header {
    border-bottom: 1px solid rgba(6, 20, 53, 0.14);
  }
  .cart-order {
    background: #fff;
  }
  .select-wrapper select {
    border: none;
    padding: 0;
  }
  .select-wrapper {
    width: 70px;
  }
  .prod-price {
    color: blue;
    font-size: 17px;
    text-transform: uppercase;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .float-co {
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 20px;
    right: 20px;
    background: #28a745;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    height: 65px;
    width: 65px;
  }
  .float-co em {
    font-size: 19px;
  }
  .float-co span {
    display: block;
    font-size: 12px;
  }
  .ic-qty {
    border: 1px solid rgba(17, 42, 99, 0.15);
    border-radius: 4px;
    border-right: 0;
    border-left: 0;
  }
  .icon-input {
    background-color: transparent;
    color: black;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  .icon-input:hover .fa {
    color: #fff;
  }
  .icon-input:hover {
    background: #061435;
    color: #fff;
    transition: all 0.5s;
  }
  .input-right {
    border-left: 1px solid rgba(17, 42, 99, 0.15);
    border-right: 1px solid rgba(17, 42, 99, 0.15);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .input-left {
    border-right: 1px solid rgba(17, 42, 99, 0.15);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid rgba(17, 42, 99, 0.15);
  }
  .card {
    box-shadow: 0px 1px 4px 2px rgba(171, 171, 171, 0.1);
    height: 249px;
  }
  .card-img {
    height: 125px;
  }
  .card-img img {
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  .card-innr {
    padding: 10px;
  }
  .coun-part .input-with-label {
    margin-top: 5px;
  }
  @media (max-width: 575px) {
    .card-innr {
      padding: 0;
    }
    .coun-part {
      padding: 0 10px;
    }
    .icon-input {
      height: 40px;
    }
    .coun-part select {
      padding: 4px 20px 2px 15px !important;
      height: 32px !important;
    }
    .coun-part .select-wrapper:after {
      font-size: 9px;
    }
  }
  @media (max-width: 768px) {
    .coun-part .input-with-label {
      display: flex;
      justify-content: center;
      margin-top: 0;
    }
    .products-pg {
      height: 300px;
    }
    .icon-input {
      padding: 14px 10px;
    }
  }
  @media (min-width: 992px) {
    .coun-part {
      display: flex;
      justify-content: center;
    }
    .coun-part .input-with-label {
      margin-left: 0.5rem;
    }
  }
  .prod {
    padding-right: 0 !important;
  }
  .cal {
    font-size: 12px;
    cursor: pointer !important;
    color: rgba(6, 20, 53, 0.8);
  }
  .qty {
    width: 100%;
    text-align: center;
  }
  .select-wrapper:after {
    width: 30px !important;
  }
  .input-item {
    padding-bottom: 0;
  }
</style>

<style scoped>
  .store-list {
    background: #ffffff;
    padding: 14px;
    -webkit-box-shadow: 0px 1px 4px 2px rgba(171, 171, 171, 0.1);
    box-shadow: 0px 1px 4px 2px rgba(171, 171, 171, 0.1);
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .store-list .store-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .store-list .store-item .item-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .store-list .store-item .item-details .item-img {
    margin-right: 15px;
  }
  .store-list .item-name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-items: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .store-list .item-name h5 {
    font-weight: 400;
  }
  .store-list .store-counter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .store-list .store-counter span {
    display: inline-block;
    margin: 4px 0;
  }
  .store-list .store-counter .count-value {
    font-size: 18px;
  }
  .store-list .store-counter button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    border-radius: 1px;
    width: 45px;
    height: 28px;
    min-width: 0;
  }
  .store-list .store-counter button.count-increment {
    background-color: #061435;
  }
  .store-list .store-counter button.count-decrement {
    background: rgba(6, 20, 53, 0.1);
  }
  .count-value {
    width: 45px;
    text-align: center;
    border: none;
    border-radius: 0;
    outline: none;
    height: 30px;
  }
  .store-list .store-item .item-details .item-img {
    width: 74px;
    min-width: 74px;
  }
  .store-list .store-item .item-details .item-img img {
    width: 100%;
    object-fit: contain;
  }
  .vue-select-group select {
    border: none;
    color: rgba(6, 20, 53, 0.7);
    background: #fff;
    margin-left: -3px;
    width: 65px;
    padding-right: 0;
    padding-left: 0;
    appearance: auto;
  }
  .btn {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
</style>
