<template>
  <div class="page-content">
    <div class="container">
      <div class="profile">
        <div class="container-fluid">
          <form @submit.prevent="changePassword()">
            <div class="form-group mb-2">
              <label for="">{{ $t("o_password") }}</label>
              <input
                type="password"
                class="form-control"
                v-model="password.currentPassword"
              />
            </div>
            <div class="form-group mb-2">
              <label for="">{{ $t("n_password") }}</label>
              <input
                type="password"
                class="form-control"
                v-model="password.newPassword"
              />
            </div>
            <div class="form-group mb-2">
              <label for="">{{ $t("cn_password") }}</label>
              <input
                type="password"
                class="form-control"
                v-model="password.password_confirmation"
              />
            </div>
            <button type="submit" :disabled="loading" class="btn btn-primary">
              <span v-if="!loading">{{ $t("c_password") }}</span>
              <span v-else> {{ $t("loading") }}</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      password: {
        currentPassword: "",
        newPassword: "",
        password_confirmation: "",
      },
    };
  },
  methods: {
    changePassword() {
      if (this.password.newPassword !== this.password.password_confirmation) {
        window.ToasterAlert(
          "error",
          "Password and Confirm password does not match"
        );
        return false;
      }
      this.loading = true;
      this.$store
        .dispatch("post", {
          endpoint: "services/app/Profile/ChangePassword",
          details: this.password,
        })
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          window.ToasterAlert("success", "Password changed successfully");
          // setTimeout(() => {
          //   this.$store.commit("logout");
          // }, 2000);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
