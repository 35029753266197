<template>
  <div class="page-content">
    <div class="container" v-if="loaded">
      <div class="card content-area">
        <div class="card-innr">
          <div
            class="card-head d-flex justify-content-between align-items-center"
          >
            <h4 class="card-title mb-0 cap">View Request</h4>
            <div>
              <button
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#uploadFile"
              >
                Upload file
              </button>

              <button
                class="btn btn-danger ml-1"
                v-if="requisitions.stockRequest.status == 1"
                data-toggle="modal"
                data-target="#cancelRequest"
              >
                <span v-if="!loading">Cancel Request</span>
                <span v-else>Loading...</span>
              </button>

              <button
                class="btn btn-secondary ml-1"
                v-if="requisitions.stockRequest.status == 4 && showReconBtn"
                @click.prevent="startReconciliation()"
                :disabled="loading"
              >
                <span v-if="!loading">Start Reconciliation</span>
                <span v-else>Loading...</span>
              </button>
            </div>
          </div>
          <div class="viewMode">
            <div class="row">
              <div class="col-12">
                <div class="card content-area mb-1">
                  <div class="data-details px-1">
                    <div class="req-summary px-3">
                      <div class="fake-class">
                        <span class="data-details-title">Request Number:</span>
                        <span class="data-details-info dd-info">{{
                          requisitions.stockRequest.requestNumber
                        }}</span>
                      </div>
                      <div class="fake-class">
                        <span class="data-details-title">Requester:</span>
                        <span class="data-details-info dd-info">{{
                          requisitions.requestedByName
                        }}</span>
                      </div>
                      <div class="fake-class">
                        <span class="data-details-title"> Request Date:</span>
                        <span class="data-details-info dd-info">{{
                          new Date(
                            requisitions.stockRequest.requestDate
                          ).toDateString()
                        }}</span>
                      </div>
                      <div class="fake-class">
                        <span class="data-details-title"> Total:</span>
                        <span class="data-details-info dd-info"
                          >{{ user.country.currencySymbol
                          }}{{ new Intl.NumberFormat().format(total) }}</span
                        >
                      </div>
                      <div class="fake-class">
                        <span class="data-details-title">Request Status:</span>

                        <span
                          v-if="requisitions.stockRequest.status == 0"
                          class="data-details-info os os-primary"
                          >Draft</span
                        >

                        <span
                          v-if="requisitions.stockRequest.status == 1"
                          class="data-details-info os os-success"
                          >Submitted</span
                        >

                        <span
                          v-if="requisitions.stockRequest.status == 2"
                          class="data-details-info os os-danger"
                          >Rejected</span
                        >

                        <span
                          v-if="requisitions.stockRequest.status == 3"
                          class="data-details-info os os-success"
                          >Approved</span
                        >

                        <span
                          v-if="requisitions.stockRequest.status == 4"
                          class="data-details-info os os-secondary"
                          >Issued</span
                        >

                        <span
                          v-if="requisitions.stockRequest.status == 5"
                          class="data-details-info os os-success"
                          >Reconciled</span
                        >

                        <span
                          v-if="requisitions.stockRequest.status == 6"
                          class="data-details-info os os-success"
                          >Closed</span
                        >
                        <span
                          v-if="requisitions.stockRequest.status == 7"
                          class="data-details-info os os-success"
                          >Approved2</span
                        >
                        <span
                          v-if="requisitions.stockRequest.status == 8"
                          class="data-details-info os os-danger"
                          >Rejected2</span
                        >
                        <span
                          v-if="requisitions.stockRequest.status == 9"
                          class="data-details-info os os-danger"
                          >Cancelled</span
                        >
                        <span
                          v-if="requisitions.stockRequest.status == 10"
                          class="data-details-info os os-success"
                          >Approved3</span
                        >
                        <span
                          v-if="requisitions.stockRequest.status == 11"
                          class="data-details-info os os-danger"
                          >Rejected3</span
                        >
                      </div>
                    </div>

                    <div class="table-responsive mt-5">
                      <table class="data-table table-striped">
                        <thead>
                          <tr class="data-item data-head">
                            <th class="data-col text-left" style="width: 35%">
                              {{ $t("product") }}
                            </th>
                            <th class="data-col" style="width: 10%">
                              {{ $t("unit") }}
                            </th>
                            <th class="data-col" style="width: 10%">
                              Unit price
                            </th>
                            <th class="data-col">Quantity Requested</th>
                            <th class="data-col">Quantity Issued</th>
                            <th class="data-col">Quantity Returned</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="data-item"
                            v-for="(
                              ord, index
                            ) in requisitions.stockRequestDetails"
                            :key="index"
                          >
                            <td class="data-col text-left pl-3">
                              <span class="sub sub-s2">{{
                                ord.productName
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.productUnitName
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">
                                {{ user.country.currencySymbol
                                }}{{
                                  formatPrice(ord.stockRequestDetail.unitPrice)
                                }}</span
                              >
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.stockRequestDetail.quantityRequested
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.stockRequestDetail.quantityIssued
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.stockRequestDetail.quantityReturned
                              }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="viewMode mt-4">
            <div class="row">
              <div class="col-12">
                <h2 class="card-title mb-2 font-weight-bold">Workflow Trail</h2>
                <div class="card content-area order-summary">
                  <div class="card-innr px-0">
                    <div class="table-responsive">
                      <table class="data-table table-striped">
                        <thead>
                          <tr class="data-item data-head">
                            <th class="data-col text-left">{{ $t("date") }}</th>
                            <th class="data-col">Personnel</th>
                            <th class="data-col">Territory</th>
                            <th class="data-col">Action Required</th>
                            <th class="data-col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="data-item"
                            v-for="(ord, index) in requisitions.workflowTrail"
                            :key="index"
                          >
                            <td class="data-col text-left pl-3">
                              <span class="sub sub-s2">{{
                                new Date(
                                  ord.stockRequestWorkflow.creationTime
                                ).toDateString()
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.processedByName
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.processorTerritoryName
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.action == 0"
                                >Approve/Reject</span
                              >
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.action == 1"
                                >Issue</span
                              >
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.action == 2"
                                >Reconcile</span
                              >
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.action == 3"
                                >Close</span
                              >
                              <span
                                class="sub sub-s2"
                                v-if="
                                  ord.stockRequestWorkflow.action == 4 ||
                                  ord.stockRequestWorkflow.action == 5
                                "
                                >Approve/Reject</span
                              >
                            </td>
                            <td class="data-col">
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.status == 0"
                              >
                                Pending
                              </span>
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.status == 1"
                              >
                                Completed
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="viewMode mt-4">
            <h2 class="card-title mb-4 font-weight-bold">Documents</h2>
            <div class="row">
              <div
                class="col-sm-4"
                v-for="(item, key) in this.requisitions.stockRequestFiles"
                :key="key"
              >
                <div class="card">
                  <a :href="item.fileURL">
                    <p class="card-text text-center">
                      {{ item.fileDescription }}
                      {{
                        item.fileType.includes("image") ? "(Image)" : "(PDF)"
                      }}
                    </p>
                    <div
                      class="docu-file"
                      v-if="item.fileType.includes('image')"
                    >
                      <img :src="item.fileURL" class="w-100 h-100" />
                    </div>
                    <div v-else>
                      <pdf :src="item.fileURL"></pdf>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="uploadFile">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Upload file</h6>
          </div>
          <a
            href="#"
            class="modal-close"
            data-dismiss="modal"
            aria-label="Close"
            ><em class="ti ti-close"></em
          ></a>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="">Name</label>
                <input type="text" v-model="description" class="form-control" />
              </div>
              <div class="form-group">
                <label for="">File</label>
                <input
                  type="file"
                  accept="image/*,.pdf"
                  id="imgFile"
                  class="form-control"
                  required
                />
              </div>

              <!-- v-if="requisitions.stockRequest.status != 9" if i don't want to show upload button if request is cancelled -->
              <button
                class="btn btn-success"
                :disabled="loading"
                @click.prevent="uploadFile()"
              >
                <span v-if="!loading">Upload</span>
                <span v-else>Loading...</span>
              </button>
            </form>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>
    <div class="modal fade" id="cancelRequest">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Cancel Request</h6>
          </div>
          <a
            href="#"
            class="modal-close"
            data-dismiss="modal"
            aria-label="Close"
            ><em class="ti ti-close"></em
          ></a>
          <div class="modal-body">
            <form>
              <div class="form-group mb-0">
                <label> Comment </label>
                <div class="input-item">
                  <textarea
                    class="form-control"
                    id=""
                    cols="30"
                    rows="5"
                    v-model="comment"
                  ></textarea>
                </div>
              </div>
              <button
                class="btn btn-success"
                :disabled="loading"
                @click.prevent="cancelRequest()"
              >
                <span v-if="!loading">Cancel Request</span>
                <span v-else>Loading...</span>
              </button>
            </form>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import axios from "axios";
  import pdf from "vue-pdf";
  export default {
    components: {
      pdf,
    },
    computed: {
      ...mapState({
        user: (context) => context.user,
      }),
    },
    data() {
      return {
        selected: true,
        loaded: false,
        requisitions: [],
        ready: false,
        loading: false,
        description: "",
        showReconBtn: true,
        total: 0,
        comment: "",
      };
    },
    methods: {
      cancelRequest() {
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: `services/app/StockRequests/CancelRequest?stockRequestId=${this.$route.params.id}`,
            details: {},
          })
          .then((resp) => {
            this.loading = false;
            console.log(resp);
            window.location.reload();
            // if (resp.data.success) {
            //   window.location = "/workflows";
            // }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      startReconciliation() {
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint:
              "services/app/StockRequests/StartReconciliation?stockRequestId=" +
              this.$route.params.id,
            details: { tenantId: this.user.country.tenantId },
          })
          .then(() => {
            this.loading = false;
            this.showReconBtn = false;
            window.ToasterAlert(
              "success",
              "Reconciliation started successfully"
            );
          })
          .catch(() => {
            this.loading = false;
          });
      },
      formatPrice(value) {
        return new Intl.NumberFormat().format(value);
      },
      getStockRequest() {
        this.$store
          .dispatch(
            "get",
            "services/app/StockRequests/GetStockRequest?stockRequestId=" +
              this.$route.params.id
          )
          .then((resp) => {
            this.loaded = true;
            // console.log(resp);
            if (resp.data.success) {
              this.requisitions = resp.data.result;
              this.requisitions.stockRequestDetails.forEach((element) => {
                this.total +=
                  element.stockRequestDetail.quantityRequested *
                  element.stockRequestDetail.unitPrice;
              });
            }
          });
      },
      uploadFile() {
        this.loading = true;
        var file = document.getElementById("imgFile").files;
        var form = new FormData();
        form.append("file", file[0]);
        form.append("fileDescription", this.description);
        form.append("stockRequestId", this.$route.params.id);

        axios
          .post(
            `${this.$store.state.endpoint_baseurl}StockRequest/UploadFile`,
            form,
            {
              headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + this.user.token,
                "Abp.TenantId": this.user.country.tenantId,
              },
            }
          )
          .then((data) => {
            this.loading = false;
            window.ToasterAlert("success", "File uploaded successfully");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
            console.log(data);
          })
          .catch((error) => {
            this.loading = false;
            this.$store.dispatch("handleError", error);
            console.log(error);
          });
      },
    },
    created() {
      this.getStockRequest();
    },
  };
</script>

<style scoped>
  .acceptR i {
    font-size: 40px;
    color: green;
  }
  .data-col {
    text-align: center;
  }
  .btn-info {
    background: #00738c;
    border: none;
  }
  .btn-success {
    background: #03a500 !important;
  }
  .down-btn .btn-sm {
    padding: 10px 18px;
    border-radius: 3px;
    font-family: plus_jakarta_displayregular;
    font-weight: bold;
  }
  .down-btn em {
    font-size: 20px;
  }
  .btn-danger {
    background: #ff0000;
  }
  .or-kil {
    font-weight: normal !important;
    font-size: 15px !important;
    font-family: plus_jakarta_displayregular;
  }
  .or-subt {
    color: rgba(17, 42, 99, 0.8);
  }

  .data-table:not(.dt-init) .data-item:last-child .data-col {
    padding-bottom: 15px;
    text-align: center;
  }
  .down-btn {
    margin-right: 10px;
  }
  .total-amt {
    font-size: 14px;
    padding: 14px 30px;
    border-radius: 4px;
    font-weight: bold;
  }
  .dd-total {
    font-size: 20px;
  }
  .dd-info {
    font-weight: bold;
    color: rgba(17, 42, 99, 0.8);
  }
  .card-sub-name {
    color: rgba(17, 42, 99, 0.8);
    font-size: 21px;
    letter-spacing: 0;
    font-weight: bold;
  }
  .fake-class {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    text-transform: capitalize;
  }
  .card {
    background-color: #f9f9f9;
  }
  .data-details,
  .order-summary {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(171, 171, 171, 0.15);
    border: none;
  }
  .sub-header {
    background-color: #eeeeee;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sub-body {
    padding-bottom: 10px;
  }
  .sb-item span {
    font-size: 14px;
  }
  .sb-item {
    display: flex;
    padding-top: 8px;
  }
  .sub-body {
    background-color: #fff;
  }
  .sub-header h5 {
    color: #112a63;
  }
  .order-d-padding {
    padding-left: 40px;
  }
  .card-title {
    color: #061435;
    font-weight: bold;
  }
  .prod-qty,
  .input-bordered {
    width: 100px;
  }
  /* .editMode .data-head .data-col{
        width: fit-content;
    } */
  .btn {
    min-width: 0;
  }
  .fake-class span {
    text-transform: capitalize;
  }
  .c-footer {
    text-align: center;
  }
  .subtotal span {
    font-size: 14px;
    font-weight: bold;
  }
  thead .data-item {
    border-bottom: 1px solid #d2dde9;
  }
  thead th {
    padding-bottom: 12px !important;
  }
  .data-head .data-col {
    color: #495463;
    width: 20%;
  }
  .data-details-info.badge {
    width: fit-content !important;
  }
  .card-sub-title {
    font-size: 20px;
    color: #112a63;
  }
  .data-details-title {
    margin-top: 7px;
  }
  @media (max-width: 576px) {
    .container {
      padding-right: 0;
      padding-left: 0;
    }
  }
</style>
