<template>
    <div class="page-content">
        <div class="container">
            <div class="content-area card">
                <div class="card-innr card-innr-fix">
                    <div class="card-head">
                        <h6 class="card-title">Customer Info</h6>
                    </div>
                    <div class="gaps-1x"></div><!-- .gaps -->
                    <form @submit.prevent="editCustomer()">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-item input-with-label">
                                    <label class="input-item-label">{{$t('fname')}}</label>
                                    <input class="input-bordered" type="text" v-model="customer.firstName">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-item input-with-label">
                                    <label class="input-item-label">{{$t('lname')}}</label>
                                    <input class="input-bordered" type="text" v-model="customer.lastName">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-item input-with-label">
                                    <label class="input-item-label">{{$t('urno')}}</label>
                                    <input class="input-bordered" type="text" v-model="customer.urno">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-item input-with-label">
                                    <label class="input-item-label">{{$t('email')}}</label>
                                    <input class="input-bordered" type="text" v-model="customer.email">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-item input-with-label">
                                    <label class="input-item-label">{{$t('phone_number')}}</label>
                                    <input class="input-bordered" type="text" v-model="customer.phoneNumber">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-item input-with-label">
                                    <label class="input-item-label">{{$t('outlet_name')}}</label>
                                    <input class="input-bordered" type="text" v-model="customer.outletName">
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="input-item input-with-label">
                                    <label class="input-item-label">Territory</label>
                                    <v-select disabled v-model="customer.territoryId" :options="territories" :reduce="territory => territory.id" label="territoryName" />
                                </div>
                            </div> -->
                            <div class="col-md-6">
                                <button class="btn btn-primary" type="submit">
                                    <span v-if="!loading">{{$t('update')}}</span>
                                    <span v-else>{{$t('loading')}}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div><!-- .card-innr -->
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';
export default {
    data(){
        return{
            customer:{},
            loading:false,
            territories:[]
        }
    },
    methods:{
        getTerritories(){
            this.$store.dispatch('get', 'services/app/Territories/GetAll')
            .then((resp)=>{
                if(resp.data.success){
                    this.territories = resp.data.result.items.map(territory=>territory.territory)
                }
            })
        },
        getCustomerForEdit(){
            this.$store.dispatch('get', 'services/app/Customers/GetCustomerForEdit?Id='+this.$route.params.CustomerId)
            .then((resp)=>{
                if(resp.data.success){
                    this.customer = resp.data.result.customer
                    // console.log(this.customer)
                }
            })
        },
        editCustomer(){
            this.loading = true
            this.$store.dispatch('post', {endpoint:'services/app/Customers/CreateOrEdit', details:this.customer})
            .then(resp=>{
                this.loading = false
                if(resp.data.success){
                    window.ToasterAlert('success','Customer update successfully')
                }else{
                    window.ToasterAlert('error',resp.data.error)
                }
            })
            .catch(error=>{
                this.loading = false
                console.log(error)
            })
        }
    },
    created(){
        this.getCustomerForEdit()
        this.getTerritories()
    }
}
</script>

<style>
.vs__dropdown-toggle{
    height:42px;
}
</style>