<template>
  <div class="page-content">
    <div class="container">
      <div
        class="dash-header d-flex justify-content-between align-items-center my-4"
      >
        <h4 class="mb-0">
          Welcome back, {{ user.agent.firstName }} {{ user.agent.lastName }}
        </h4>
      </div>
      <carousel
        :nav="false"
        :items="4"
        :margin="8"
        :center="false"
        :stagePadding="20"
        :responsive="{ 0: { items: 1 }, 600: { items: 3 }, 768: { items: 4 } }"
      >
        <router-link to="/customers">
          <div class="token-information card card-full-height bg-primary">
            <div class="token-info text-center">
              <em
                class="ti ti-plus token-info-icon font-weight-bold text-white mb-1"
                style="font-size: 30px"
              ></em>
              <span class="token-info-sub text-white">{{
                $t("new_order")
              }}</span>
            </div>
          </div>
        </router-link>
        <router-link to="/customers">
          <div class="token-information card card-full-height o-customer">
            <div class="token-info text-center">
              <h1 class="token-info-head text-white">{{ customers.length }}</h1>
              <span class="token-info-sub text-white">{{
                $t("customers")
              }}</span>
            </div>
          </div>
        </router-link>
        <router-link to="/orders">
          <div class="token-information card card-full-height o-pending">
            <div class="token-info text-center">
              <h1 class="token-info-head text-white">{{ pending }}</h1>
              <span class="token-info-sub text-white">{{
                $t("p_orders")
              }}</span>
            </div>
          </div>
        </router-link>
        <router-link to="/orders">
          <div class="token-information card card-full-height o-success">
            <div class="token-info text-center">
              <h1 class="token-info-head text-white">{{ delivered }}</h1>
              <span class="token-info-sub text-white">{{
                $t("dd_orders")
              }}</span>
            </div>
          </div>
        </router-link>
      </carousel>

      <div class="row">
        <div class="col-lg-8">
          <div class="card content-area">
            <div class="card-innr">
              <div
                class="card-head d-flex justify-content-between align-items-center mb-2"
              >
                <h6 class="card-title">{{ $t("r_orders") }}</h6>
                <div class="card-opt">
                  <router-link to="/orders" class="link ucap"
                    >{{ $t("v_all") }}
                    <em class="fas fa-angle-right ml-2"></em>
                    <em class="fas fa-angle-right"></em
                  ></router-link>
                </div>
              </div>
              <div v-if="orders.length > 0">
                <div class="d-none d-md-block">
                  <div class="table-responsive">
                    <table class="data-table dt-init kyc-list table-striped">
                      <thead>
                        <tr class="data-item data-head">
                          <th class="table-col">{{ $t("order_no") }}</th>
                          <th class="table-col">{{ $t("Date") }}</th>
                          <th class="table-col">{{ $t("customer") }}</th>
                          <th class="table-col">{{ $t("urno") }}</th>
                          <th class="table-col">{{ $t("price") }}</th>
                          <th
                            class="table-col"
                            v-if="user.country.tenantId != 1"
                          >
                            {{ $t("validated") }}
                          </th>
                          <th class="table-col dt-status">
                            {{ $t("o_status") }}
                          </th>
                          <th class="table-col">{{ $t("action") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="data-item"
                          v-for="order in orders.slice(0, 5)"
                          :key="order.order.id"
                          @click="$router.push(`/order/${order.order.id}`)"
                        >
                          <td class="table-col">
                            <span class="sub sub-s2">{{
                              order.order.orderNumber
                            }}</span>
                          </td>
                          <td class="table-col">
                            <span class="sub sub-s2">
                              {{
                                order.order.orderDate
                                  | moment("D MMM YYYY h:mm a")
                              }}
                              <!-- {{
                                new Date(order.order.orderDate).toDateString()
                              }} -->
                            </span>
                          </td>

                          <td class="table-col">
                            <span class="sub sub-s2">{{
                              order.customer.firstName
                            }}</span>
                          </td>
                          <td class="table-col">
                            <span class="sub sub-s2">{{
                              order.customer.urno
                            }}</span>
                          </td>
                          <td class="table-col">
                            <span class="sub sub-s2"
                              >{{ user.country.currency }}
                              {{
                                formatPrice(order.order.orderTotalPrice)
                              }}</span
                            >
                          </td>

                          <td
                            class="table-col"
                            v-if="user.country.tenantId != 1"
                          >
                            <div class="table-flex">
                              <div
                                class="dot"
                                :class="
                                  order.order.isValidated
                                    ? 'dot-success'
                                    : 'dot-danger'
                                "
                              ></div>
                              <span
                                class="sub sub-s2"
                                :class="
                                  order.order.isValidated
                                    ? 'dot-t-success'
                                    : 'dot-t-danger'
                                "
                                >{{ order.order.isValidated }}</span
                              >
                            </div>
                          </td>
                          <td class="table-col">
                            <div class="table-flex">
                              <div
                                class="dot"
                                :class="
                                  order.order.orderStatus == 0
                                    ? 'dot-warning'
                                    : order.order.orderStatus == 1
                                    ? 'dot-success'
                                    : 'dot-danger'
                                "
                              ></div>
                              <span
                                class="sub sub-s2"
                                :class="
                                  order.order.orderStatus == 0
                                    ? 'dot-t-warning'
                                    : order.order.orderStatus == 1
                                    ? 'dot-t-success'
                                    : 'dot-t-danger'
                                "
                                >{{
                                  order.order.orderStatus == 0
                                    ? $t("pending")
                                    : order.order.orderStatus == 1
                                    ? $t("delivered")
                                    : $t("cancelled")
                                }}</span
                              >
                            </div>
                          </td>
                          <td class="table-col text-center">
                            <router-link
                              :to="'/order/' + order.order.id"
                              class="eye"
                              ><em class="ti ti-eye"></em
                            ></router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="m-recent-orders d-md-none d-block">
                  <div
                    class="mro-item"
                    v-for="order in orders.slice(0, 5)"
                    :key="order.order.id"
                  >
                    <router-link
                      :to="'/order/' + order.order.id"
                      class="d-flex justify-content-between"
                    >
                      <div class="mro-left">
                        <div class="mrol-wrapper d-flex">
                          <div
                            class="mrol-icon"
                            :class="
                              order.order.deliveryStatus == 1
                                ? 'success'
                                : order.order.deliveryStatus == 0
                                ? 'pending'
                                : 'danger'
                            "
                          >
                            <i class="fa fa-arrow-up"></i>
                          </div>

                          <div class="mrol-main ml-2">
                            <p>{{ order.customer.outletName }}</p>
                            <span class="order-id"
                              >ORDER ID: {{ order.order.orderNumber }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="mro-right text-right">
                        <p>
                          {{ user.country.currencySymbol }}
                          {{ formatPrice(order.order.orderTotalPrice) }}
                        </p>
                        <span>{{
                          order.order.orderStatus == 0
                            ? $t("p_order")
                            : order.order.orderStatus == 1
                            ? $t("ds_order")
                            : $t("cs_order")
                        }}</span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div
                class="text-center text-dark font-weight-bold alert alert-info"
                v-else
              >
                <p>No recent orders</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card content-area">
            <div class="card-innr">
              <div
                class="card-head d-flex justify-content-between align-items-center mb-2"
              >
                <h6 class="card-title">
                  {{ $t("customers") }} ({{ customers.length }})
                </h6>
                <div class="card-opt">
                  <router-link to="/customers" class="link ucap"
                    >{{ $t("v_all") }} <em class="fas fa-angle-right ml-2"></em
                    ><em class="fas fa-angle-right"></em
                  ></router-link>
                </div>
              </div>
              <div class="table-responsive">
                <table class="data-table dt-init kyc-list">
                  <thead>
                    <tr class="data-item data-head">
                      <th class="data-col dt-user">{{ $t("name") }}</th>
                      <th class="data-col">{{ $t("phone") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="data-item"
                      v-for="customer in customers.slice(0, 7)"
                      :key="customer.customer.id"
                    >
                      <td class="data-col dt-user">
                        <span class="sub sub-s2"
                          >{{ customer.customer.firstName }}
                          {{ customer.customer.lastName }}</span
                        >
                      </td>
                      <td class="data-col dt-user">
                        <span class="sub sub-s2">{{
                          customer.customer.phoneNumber
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- .card-innr -->
          </div>
        </div>
      </div>
      <div class="row feeds" v-if="user.country.tenantId == 1">
        <div class="col-12 mb-3">
          <div class="d-flex justify-content-between">
            <h6 class="card-title">Feeds</h6>
            <router-link to="/newsfeeds">View all</router-link>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              Hello. BAT is offering you an opportunity to win N10k voucher !!!
              All you have to do is very simple … PLAY the engagement video on
              your mobile trader device to your customers in outlets with the
              blue Project Cheetah poster and tell them to ask the smokers who
              buy cigarettes in their outlets to follow the prompt on the QR
              code or USSD to register. Don’t forget, your customer can also win
              N50k or 1 roll of Rothmans oo!
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              Hello, your customers have a chance to win either N50K or a roll
              of Rothmans when consumers who buy cigarettes in their outlets
              register through the USSD or QR code. Play the project cheetah
              video to your customers and you also could stand a chance to win
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="video-card card">
            <video
              src="https://stagingretailmarket.blob.core.windows.net/assets/BAT-VID1.mp4"
              controls
              class="card-img-top"
            ></video>
            <div class="card-body p-0 pt-2 pb-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import carousel from "vue-owl-carousel";
  export default {
    components: { carousel },
    computed: {
      ...mapState({
        user: (context) => context.user,
      }),
    },
    data() {
      return {
        delivered: 0,
        pending: 0,
        cancelled: 0,
        orders: [],
        customers: [],
      };
    },
    methods: {
      formatPrice(value) {
        return new Intl.NumberFormat().format(value);
      },
      getRecentOrders() {
        this.$store
          .dispatch(
            "get",
            "services/app/Agents/GetOrders?agentid=" + this.user.agent.id
          )
          .then((resp) => {
            console.log(resp);
            if (resp.data.success) {
              this.orders = resp.data.result.items;
              if (this.orders.length > 0) {
                this.orders.forEach((element) => {
                  if (element.order.orderStatus == 2) {
                    this.cancelled += 1;
                  } else if (element.order.orderStatus == 1) {
                    this.delivered += 1;
                  } else {
                    this.pending += 1;
                  }
                });
              }
            }
          })
          .catch((error) => {
            this.$store.dispatch("handleError", error);
          });
      },
      getCustomers() {
        this.$store
          .dispatch(
            "get",
            "services/app/Agents/GetRoutePlan?agentid=" + this.user.agent.id
          )
          .then((resp) => {
            if (resp.data.success) {
              this.customers = resp.data.result;
            }
          })
          .catch((error) => {
            this.$store.dispatch("handleError", error);
          });
      },
    },
    created() {
      this.getRecentOrders();
      this.getCustomers();
      console.log(this.user);
    },
  };
</script>

<style scoped>
  .feeds .card-body h5 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0;
  }
  .video-card {
    background: transparent;
  }
  .feeds .video-card {
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .feeds .card-img-top {
    border-radius: 10px;
  }
  .card-title {
    font-size: 17px;
    font-weight: bold;
    color: #112a63e5;
  }
  thead .data-item {
    border-bottom: 1px solid #d2dde9;
  }
  thead th {
    padding-bottom: 12px !important;
  }
  .table-col .sub-s2 {
    font-family: plus_jakarta_displayregular;
    color: #061435;
    font-size: 11px;
  }
  .data-head .table-col {
    text-transform: capitalize;
  }
  .card-full-height {
    height: 100px;
  }
  .token-info-sub {
    font-weight: bold;
  }
  .outs {
    padding: 2px 5px !important;
    width: max-content !important;
    color: #fff;
    min-width: auto;
  }
  .rebtn {
    min-width: 114px;
  }
  thead .data-item {
    border-bottom: 1px solid #d2dde9;
  }
  thead th {
    padding-bottom: 12px !important;
  }
  .data-head .data-col {
    color: #495463;
  }
  .token-info-head {
    font-weight: bold;
    font-size: 28px;
  }
  .dash-header h4 {
    font-weight: bold;
    color: black;
  }
</style>
