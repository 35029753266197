<template>
  <div class="page-content">
    <div class="container">
      <div class="card content-area">
        <div class="card-innr" v-if="ready">
          <div
            class="card-head d-flex justify-content-between align-items-center"
          >
            <h4 class="card-title mb-0 cap" v-if="viewMode">
              {{ $t("o_details") }}
            </h4>
            <h4 class="card-title mb-0 cap" v-else>
              {{ $t("e_order") }}
            </h4>
            <div>
              <button
                v-if="order.order.orderStatus === 0"
                class="btn btn-primary edit-icon"
                @click="toggleEditMode()"
              >
                <span v-if="viewMode">
                  <i class="fa fa-edit"></i> {{ $t("edit") }}
                </span>
                <span v-else>
                  <i class="fa fa-chevron-left"></i> {{ $t("back") }}
                </span>
              </button>
            </div>
          </div>
          <div class="viewMode" v-if="viewMode">
            <div class="gaps-1-5x"></div>
            <div class="row mt-4">
              <!-- <div class="col-sm-8">
                                <div class="order-d">
                                    <div class="sub-header order-d-padding">
                                        <h5>Order Summary</h5>
                                    </div>
                                    <div class="sub-body">
                                        <div class="sb-main order-d-padding">
                                            <div class="sb-item">
                                                <span>{{$t('order_id')}}:</span>
                                                <span class="ml-3">#{{order.order.orderNumber}}</span>
                                            </div>
                                            <div class="sb-item">
                                                <span>{{$t('o_date')}}:</span>
                                                <span class="ml-3">{{new Date(order.order.orderDate).toDateString()}}</span>
                                            </div>
                                            <div class="sb-item">
                                                <span>{{$t('to_quant')}}:</span>
                                                <span class="ml-3">{{order.order.orderTotalQuantity}}</span>
                                            </div>
                                            <div class="sb-item">
                                                <span>{{$t('o_status')}}:</span>
                                                <span class="ml-3 badge" :class="order.order.orderStatus === 0 ? 'badge-primary' : (order.order.orderStatus === 1 ? 'badge-success' : 'badge-danger')">
                                                    {{order.order.orderStatus === 0 ? 'Pending' : (order.order.orderStatus === 1 ? 'Delivered' : 'Cancelled')}}
                                                </span>
                                            </div>
                                            <div class="sb-item">
                                                <span>{{$t('total')}}:</span>
                                                <span class="ml-3 ucap">{{this.$store.state.user.country.currencySymbol}} {{formatPrice(order.order.orderTotalPrice)}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sub-header order-d-padding">
                                        <h5>Order Summary</h5>
                                    </div>
                                    <div class="sub-body">
                                        <div class="sb-main order-d-padding">
                                            <div class="sb-item">
                                                <span>Order ID:</span>
                                                <span class="ml-3">#6663773</span>
                                            </div>
                                            <div class="sb-item">
                                                <span>Order ID:</span>
                                                <span class="ml-3">#6663773</span>
                                            </div>
                                            <div class="sb-item">
                                                <span>Order ID:</span>
                                                <span class="ml-3">#6663773</span>
                                            </div>
                                            <div class="sb-item">
                                                <span>Order ID:</span>
                                                <span class="ml-3">#6663773</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

              <div class="col-sm-5">
                <div class="card content-area mb-1">
                  <div class="card-innr px-1 pt-0">
                    <div class="data-details">
                      <h2 class="card-sub-name mb-5 font-weight-bold">
                        {{ order.customerFirstName }}
                      </h2>
                      <div class="">
                        <div class="fake-class">
                          <span class="data-details-title"
                            >{{ $t("order_no") }}:</span
                          >
                          <span class="data-details-info dd-info">{{
                            order.order.orderNumber
                          }}</span>
                        </div>
                        <div class="fake-class">
                          <span class="data-details-title">
                            {{ $t("o_date") }}:</span
                          >
                          <span class="data-details-info dd-info">{{
                            order.order.orderDate | moment("D MMM YYYY h:mm a")
                          }}</span>
                        </div>
                        <div class="fake-class">
                          <span class="data-details-title"
                            >{{ $t("t_line_items") }}:</span
                          >
                          <span class="data-details-info dd-info">{{
                            formatPrice(order.order.orderTotalQuantity)
                          }}</span>
                        </div>
                        <div class="fake-class">
                          <span class="data-details-title"
                            >{{ $t("p_method") }}:</span
                          >
                          <span
                            class="data-details-info dd-info"
                            v-if="order.order.orderPaymentMethod == 0"
                            >{{ $t("cash") }}</span
                          >
                          <span
                            class="data-details-info dd-info"
                            v-if="order.order.orderPaymentMethod == 1"
                            >{{ $t("credit") }}</span
                          >
                          <span
                            class="data-details-info dd-info"
                            v-if="order.order.orderPaymentMethod == 2"
                            >{{ $t("pos") }}</span
                          >
                          <span
                            class="data-details-info dd-info"
                            v-if="order.order.orderPaymentMethod == 3"
                            >{{ $t("transfer") }}</span
                          >
                        </div>
                        <div
                          class="fake-class"
                          v-if="user.country.tenantId != 1"
                        >
                          <span class="data-details-title"
                            >{{ $t("order_val") }}:</span
                          >
                          <span
                            class="data-details-info os"
                            :class="
                              order.order.isValidated
                                ? 'os-outline-success'
                                : 'os-outline-danger'
                            "
                            >{{ order.order.isValidated }}</span
                          >
                        </div>

                        <div class="fake-class">
                          <span class="data-details-title"
                            >{{ $t("o_status") }}:</span
                          >
                          <span
                            class="data-details-info os"
                            :class="
                              order.order.orderStatus === 0
                                ? 'os-primary'
                                : order.order.orderStatus === 1
                                ? 'os-success'
                                : 'os-danger'
                            "
                          >
                            {{
                              order.order.orderStatus === 0
                                ? $t("pending")
                                : order.order.orderStatus == 1
                                ? $t("delivered")
                                : $t("cancelled")
                            }}
                          </span>
                          <!-- <span class="data-details-info badge badge-danger">Cancelled</span> -->
                        </div>
                        <div class="fake-class">
                          <span class="data-details-title"
                            >{{ $t("c_status") }}:</span
                          >

                          <span
                            class="data-details-info os"
                            :class="
                              order.order.deliveryStatus === 0
                                ? 'os-primary'
                                : order.order.deliveryStatus === 1
                                ? 'os-success'
                                : 'os-danger'
                            "
                          >
                            {{
                              order.order.deliveryStatus === 0
                                ? $t("pending")
                                : order.order.deliveryStatus === 1
                                ? $t("received")
                                : "Not Received"
                            }}
                          </span>
                        </div>
                        <div class="fake-class">
                          <span class="data-details-title"
                            >{{ $t("o_channel") }}:</span
                          >
                          <OrderChannel
                            :channel="order.order.orderChannel"
                          ></OrderChannel>
                        </div>
                        <div
                          class="fake-class"
                          v-if="order.order.externalReference"
                        >
                          <span class="data-details-title"
                            >Customer reference number:</span
                          >
                          <span class="data-details-info os">{{
                            order.order.externalReference
                          }}</span>
                        </div>

                        <div class="fake-class mt-5">
                          <span
                            class="data-details-title font-weight-bold dd-total ucap"
                            >{{ $t("total") }}</span
                          >
                          <span
                            class="data-details-info badge badge-md btn-primary ucap total-amt"
                            >{{ this.$store.state.user.country.currency }}
                            {{ formatPrice(order.order.orderTotalPrice) }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="card content-area order-summary">
                  <div class="card-innr px-0">
                    <h2 class="card-sub-name mb-5 font-weight-bold pl-3">
                      {{ $t("summary") }}
                    </h2>
                    <div class="table-responsive">
                      <table class="data-table table-striped">
                        <thead>
                          <tr class="data-item data-head">
                            <th class="data-col dt-user">{{ $t("s_n") }}</th>
                            <th class="data-col">{{ $t("product") }}</th>
                            <th class="data-col">{{ $t("unit") }}</th>
                            <th class="data-col">{{ $t("qty") }}</th>
                            <!-- <th class="data-col">Price</th> -->
                            <th class="data-col dt-status">
                              {{ $t("total") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="data-item"
                            v-for="(ord, index) in order.orderDetails"
                            :key="ord.id"
                          >
                            <td class="data-col dt-user">
                              <span class="sub sub-s2 pl-4"
                                >{{ ++index }}.</span
                              >
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.productName
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.productUnitName
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.orderDetail.productQuantity
                              }}</span>
                            </td>
                            <!-- <td class="data-col">
                                                            <span class="sub sub-s2">{{user.country.currency}} {{formatPrice(ord.orderDetail.productUnitPrice)}}</span>
                                                        </td> -->
                            <td class="data-col">
                              <span class="sub sub-s2"
                                >{{ user.country.currency }}
                                {{
                                  formatPrice(ord.orderDetail.productTotalPrice)
                                }}</span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="subtotal d-flex justify-content-end mt-3 mr-4">
                      <div class="sub-con">
                        <span class="pr-5 ucap or-subt">{{
                          $t("subtotal")
                        }}</span>
                        <span class="badge btn-primary ucap total-amt or-kil"
                          >{{ user.country.currency }}
                          {{ formatPrice(order.order.orderTotalPrice) }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-footer d-block w-100 d-md-flex justify-content-end">
                <button
                  class="mr-2 py-2 btn mt-2 btn-sm btn-success"
                  @click="paymentOption()"
                  v-if="order.order.orderStatus != 1"
                >
                  <em class="text-white ti ti-check"></em>
                  <span class="cap">{{ $t("confirm_delivery") }}</span>
                </button>

                <button
                  class="mr-2 py-2 btn mt-2 btn-sm btn-danger"
                  @click="cancelOrder()"
                  :disabled="loading"
                >
                  <div v-if="!loading">
                    <em class="text-white ti ti-na"></em>
                    <span class="cap">{{ $t("c_order") }}</span>
                  </div>

                  <span v-else>Loading...</span>
                </button>

                <!-- <button
                  class="mr-2 py-2 btn mt-2 btn-sm btn-secondary"
                  @click="confirmOrder()"
                  v-if="!order.order.isValidated"
                >
                  <em class="text-white fa fa-calendar-check"></em>
                  <span>Confirm Order</span>
                </button> -->

                <a
                  class="btn mt-2 btn-sm btn-primary"
                  href="javascript: window.print()"
                >
                  <em class="text-white ti ti-printer"></em>
                  <span class="cap">{{ $t("print") }}</span>
                </a>
              </div>
            </div>
          </div>
          <div class="editMode" v-else>
            <div class="gaps-1-5x"></div>
            <div class="data-details py-4">
              <h5
                class="card-sub-title mb-4 font-weight-bold"
                style="letter-spacing: 1px; font-size: 15px"
              >
                {{ $t("add_new_prod") }}
              </h5>
              <form ref="submitForm" @submit.prevent="addProduct()">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <label>{{ $t("products") }}</label>
                      <v-select
                        v-model="newOrder.product"
                        :options="filterProducts"
                        label="productName"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <label>{{ $t("unit") }}</label>
                      <v-select
                        v-model="newOrder.prodUnitId"
                        :options="units"
                        :reduce="(unit) => unit.id"
                        label="productUnitName"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group">
                      <label>{{ $t("quantity") }}</label>
                      <input
                        type="number"
                        step=".01"
                        v-model="newOrder.quantity"
                        value="0"
                        min="1"
                        class="form-control"
                        style="height: 42px"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <button type="submit" class="btn btn-info">
                      {{ $t("add_prod") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="gaps-3x"></div>
            <div class="card content-area order-summary">
              <div class="card-innr px-0">
                <h5
                  class="card-sub-title mb-4 pl-3 font-weight-bold"
                  style="letter-spacing: 1px; font-size: 15px"
                >
                  {{ $t("summary") }}
                </h5>
                <div class="table-responsive">
                  <table class="data-table dt-init kyc-list table-striped">
                    <thead>
                      <tr class="data-item data-head">
                        <th class="data-col dt-user">{{ $t("s_n") }}</th>
                        <th class="data-col">{{ $t("product") }}</th>
                        <th class="data-col">{{ $t("unit") }}</th>
                        <th class="data-col">{{ $t("qty") }}</th>
                        <th class="data-col">{{ $t("total") }}</th>
                        <th class="data-col">{{ $t("action") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="data-item"
                        v-for="(ord, index) in editOrder.orderDetails"
                        :key="ord.id"
                      >
                        <td class="data-col dt-user">
                          <span class="sub sub-s2">{{ ++index }}</span>
                        </td>
                        <td class="data-col">
                          <span class="sub sub-s2">{{ ord.productName }}</span>
                        </td>
                        <td class="data-col">
                          <select
                            :class="ord.productId"
                            @change="updateProductUnitId($event, ord.productId)"
                            class="input-bordered"
                          >
                            <option
                              v-for="(unit, index) in units"
                              :key="index"
                              :value="unit.id"
                              :selected="
                                ord.productUnitName == unit.productUnitName
                                  ? selected
                                  : ''
                              "
                            >
                              {{ unit.productUnitName }}
                            </option>
                          </select>
                        </td>
                        <td class="data-col">
                          <!-- <span class="sub sub-s2">{{ord.productQuantity}}</span> -->
                          <input
                            type="number"
                            step=".01"
                            min="1"
                            @input="changeQty(ord.productId)"
                            :value="ord.productQuantity"
                            class="form-control prod-qty"
                            :id="ord.productId"
                          />
                        </td>
                        <td class="data-col">
                          <span class="sub sub-s2"
                            >{{ user.country.currency }}
                            {{ formatPrice(ord.productTotalPrice) }}</span
                          >
                        </td>
                        <td class="data-col">
                          <button
                            class="btn btn-danger py-0 px-2"
                            @click="deleteOrder(ord.productId)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    class="subtotal d-block d-sm-flex justify-content-between align-items-center py-3 px-3"
                  >
                    <div class="sub-con">
                      <button class="btn btn-success" @click="updateOrder()">
                        <span v-if="!loading">{{ $t("up_order") }} </span>
                        <span v-else>{{ $t("loading") }}</span>
                      </button>
                    </div>
                    <div class="sub-con mt-3 mt-sm-0">
                      <span class="pr-5">{{ $t("subtotal") }}:</span>
                      <span class="badge btn-primary ucap total-amt or-kil"
                        >{{ user.country.currency }}
                        {{ formatPrice(calculateTotal) }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="paymentModal">
          <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
            <div class="modal-content">
              <a
                href="#"
                class="modal-close"
                data-dismiss="modal"
                aria-label="Close"
                ><em class="ti ti-close"></em
              ></a>
              <div class="popup-body">
                <form @submit.prevent="confirmCashPayment()">
                  <div class="form-group">
                    <label> Select Payment option </label>
                    <div class="input-item mt-2">
                      <input
                        type="radio"
                        class="input-radio"
                        checked
                        name="example_radio"
                        id="radio_1"
                      />
                      <label for="radio_1">Cash</label>
                    </div>
                  </div>
                  <button class="btn btn-success" :disabled="loading">
                    <span v-if="!loading">Deliver Order</span>
                    <span v-else>Loading...</span>
                  </button>
                </form>
              </div>
            </div>
            <!-- .modal-content -->
          </div>
          <!-- .modal-dialog -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import OrderChannel from "./widget/orderChannel.vue";
  export default {
    components: { OrderChannel },
    computed: {
      ...mapState({
        user: (context) => context.user,
      }),
      calculateTotal() {
        let total = 0;
        this.editOrder.orderDetails.forEach((element) => {
          total += element.productTotalPrice;
        });
        return total.toFixed(2);
      },
    },
    data() {
      return {
        selected: true,
        order: {},
        ready: false,
        delivered: 0,
        pending: 0,
        cancelled: 0,
        viewMode: true,
        products: [],
        units: [],
        newOrder: {
          quantity: 1,
        },
        editOrder: {},
        filterProducts: [],
        loading: false,
      };
    },
    methods: {
      paymentOption() {
        window.$("#paymentModal").modal("show");
      },
      updateOrder() {
        this.editOrder.orderTotalPrice = this.calculateTotal;
        var totalQty = 0;
        this.editOrder.orderDetails.forEach((element) => {
          totalQty += parseFloat(element.productQuantity);
        });
        this.editOrder.orderTotalQuantity = parseFloat(totalQty);
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: "services/app/Orders/CreateOrEdit",
            details: this.editOrder,
          })
          .then((resp) => {
            if (resp.data.success) {
              this.loading = false;
              this.getOrder();
              window.SweetAlert("success", "Order updated successfully.");
              this.viewMode = true;
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$store.dispatch("handleError", error);
          });
      },
      deleteOrder(id) {
        this.editOrder.orderDetails.forEach((item, index) => {
          if (item.productId == id) {
            this.editOrder.orderDetails.splice(index, 1);
          }
        });
        var sProd = this.products.filter(
          (product) => product.product.id == id
        )[0];
        this.filterProducts.push(sProd.product);
      },
      changeQty(id) {
        var num = parseFloat(document.getElementById(id).value);
        if (!isNaN(num)) {
          var sProd = this.products.filter(
            (product) => product.product.id == id
          )[0];
          this.editOrder.orderDetails.forEach((order) => {
            if (order.productId == id) {
              order.productQuantity = num;
              var baseUnitFactor = 0;
              this.units.forEach((item) => {
                if (sProd.product.productBaseUnitId == item.id) {
                  baseUnitFactor = item.conversionFactor;
                }
              });
              this.units.forEach((item) => {
                if (order.productUnitId == item.id) {
                  var conversionFactor = item.conversionFactor;
                  order.convertedPrice =
                    (parseFloat(baseUnitFactor) /
                      parseFloat(conversionFactor)) *
                    sProd.product.unitPrice;
                }
              });
              order.productTotalPrice =
                order.productQuantity * order.convertedPrice;
            }
          });
        }
      },
      addProduct() {
        if (this.newOrder.quantity < 1) {
          window.ToasterAlert("error", "Invalid quantity");
          return false;
        }
        if (!this.newOrder.prodUnitId) {
          window.ToasterAlert("error", "Unit is required");
          return false;
        }
        if (!this.newOrder.product) {
          window.ToasterAlert("error", "Product is required");
          return false;
        }
        var newProd = {};
        newProd.productName = this.newOrder.product.productName;
        newProd.productQuantity = this.newOrder.quantity;
        newProd.productId = this.newOrder.product.id;
        newProd.productUnitId = this.newOrder.prodUnitId;
        var baseUnitFactor = 0;
        this.units.forEach((item) => {
          if (this.newOrder.product.productBaseUnitId == item.id) {
            baseUnitFactor = item.conversionFactor;
          }
        });
        this.units.forEach((item) => {
          if (newProd.productUnitId == item.id) {
            newProd.productUnitName = item.productUnitName;
            var conversionFactor = item.conversionFactor;
            newProd.convertedPrice =
              (parseFloat(baseUnitFactor) / parseFloat(conversionFactor)) *
              this.newOrder.product.unitPrice;
          }
        });
        newProd.productTotalPrice =
          newProd.productQuantity * newProd.convertedPrice;
        this.editOrder.orderDetails.push(newProd);
        this.removeProductFromProducts();
        this.newOrder.product = "";
        this.newOrder.prodUnitId = "";
      },
      updateProductUnitId(event, productId) {
        // console.log(event.target.value)
        // console.log(productId)
        var num = parseFloat(document.getElementById(productId).value);
        var sProd = this.products.filter(
          (product) => product.product.id == productId
        )[0];
        this.editOrder.orderDetails.forEach((order) => {
          if (order.productId == productId) {
            order.productUnitId = event.target.value;
            order.productUnitName = this.units.filter(
              (unit) => unit.id == event.target.value
            )[0].productUnitName;
            order.productQuantity = num;
            var baseUnitFactor = 0;
            this.units.forEach((item) => {
              if (sProd.product.productBaseUnitId == item.id) {
                baseUnitFactor = item.conversionFactor;
              }
            });
            this.units.forEach((item) => {
              if (order.productUnitId == item.id) {
                var conversionFactor = item.conversionFactor;
                order.convertedPrice =
                  (parseFloat(baseUnitFactor) / parseFloat(conversionFactor)) *
                  sProd.product.unitPrice;
              }
            });
            order.productTotalPrice =
              order.productQuantity * order.convertedPrice;
          }
        });
      },
      toggleEditMode() {
        this.viewMode = !this.viewMode;
      },
      formatPrice(value) {
        return new Intl.NumberFormat().format(value);
      },
      confirmCashPayment() {
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint:
              "services/app/Cash/ConfirmPayment?orderId=" + this.order.order.id,
            details: {},
          })
          .then(() => {
            this.deliveryOrder();
          })
          .catch(() => {
            this.loading = false;
          });
      },
      deliveryOrder() {
        this.$store
          .dispatch("post", {
            endpoint:
              "services/app/Orders/DeliverOrder?orderId=" + this.order.order.id,
            details: { tenantId: this.user.country.tenantId },
          })
          .then((resp) => {
            // console.log(resp)
            this.loading = false;
            window.$("#paymentModal").modal("hide");
            if (resp.data.success) {
              this.getOrder();
              window.SweetAlert("success", "Order delivered successfully");
            } else {
              window.SweetAlert("error", "Error delivering order");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      async getOrder() {
        await this.$store
          .dispatch(
            "get",
            "services/app/Orders/GetOrderForView?id=" + this.$route.params.id
          )
          .then((resp) => {
            if (resp.data.success) {
              this.ready = true;
              this.order = resp.data.result;

              this.editOrder = { ...this.order.order, orderDetails: [] };
            }
          });
      },
      confirmOrder() {
        this.$store
          .dispatch("post", {
            endpoint:
              "services/app/Orders/ConfirmOrder?orderId=" + this.order.order.id,
            details: { tenantId: this.user.country.tenantId },
          })
          .then((resp) => {
            if (resp.data.success) {
              this.getOrder();
              window.SweetAlert("success", "Order confirm successfully");
            } else {
              window.SweetAlert("error", "Error confirming order");
            }
          });
      },
      cancelOrder() {
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint:
              "services/app/Orders/CancelOrder?orderId=" + this.order.order.id,
            details: { tenantId: this.user.country.tenantId },
          })
          .then((resp) => {
            this.loading = false;
            if (resp.data.success) {
              this.getOrder();
              window.SweetAlert("success", "Order cancelled successfully");
            } else {
              window.SweetAlert("error", "Error cancelling order");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      getProducts() {
        this.$store
          .dispatch(
            "getProduct",
            "services/app/Products/GetAll?MaxResultCount=1000"
          )
          .then((resp) => {
            // console.log(resp)
            if (resp.data.success) {
              this.products = resp.data.result.items;
              this.filterProducts = this.products.map(
                (product) => product.product
              );

              this.order.orderDetails.forEach((item) => {
                this.products.forEach((prod) => {
                  if (prod.product.id == item.orderDetail.productId) {
                    var saved = {};
                    saved.productUnitPrice = prod.product.unitPrice;
                    saved.productTotalPrice =
                      item.orderDetail.productTotalPrice;
                    saved.productQuantity = item.orderDetail.productQuantity;
                    saved.productId = item.orderDetail.productId;
                    saved.productUnitId = item.orderDetail.productUnitId;
                    saved.id = item.orderDetail.id;
                    saved.orderId = item.orderDetail.orderId;
                    saved.productName = item.productName;
                    saved.productUnitName = item.productUnitName;
                    this.editOrder.orderDetails.push(saved);
                  }
                });
              });
              this.removeProductFromProducts();
            }
          });
      },
      removeProductFromProducts() {
        this.editOrder.orderDetails.forEach((item) => {
          this.filterProducts.forEach((prod) => {
            if (prod.id == item.productId) {
              this.filterProducts.splice(
                this.filterProducts.findIndex((p) => p.id == item.productId),
                1
              );
            }
          });
        });
      },
      getUnits() {
        this.$store
          .dispatch("getWithTalent", "services/app/ProductUnits/GetAll")
          .then((resp) => {
            if (resp.data.success) {
              // this.units = resp.data.result.items
              this.units = resp.data.result.items.map(
                (unit) => unit.productUnit
              );
              // console.log(this.units)
            }
          });
      },
    },
    async created() {
      await this.getOrder();
      this.getProducts();
      this.getUnits();
    },
  };
</script>

<style scoped>
  .editMode .data-col {
    text-align: center;
  }
  .btn-info {
    background: #00738c;
    border: none;
  }
  .btn-success {
    background: #03a500 !important;
  }
  .down-btn .btn-sm {
    padding: 10px 18px;
    border-radius: 3px;
    font-family: plus_jakarta_displayregular;
    font-weight: bold;
  }
  .down-btn em {
    font-size: 20px;
  }
  .btn-danger {
    background: #ff0000;
  }
  .or-kil {
    font-weight: normal !important;
    font-size: 15px !important;
    font-family: plus_jakarta_displayregular;
  }
  .or-subt {
    color: rgba(17, 42, 99, 0.8);
  }
  .data-table:not(.dt-init) .data-item:last-child .data-col {
    padding-bottom: 15px;
  }
  .down-btn {
    margin-right: 10px;
  }
  .total-amt {
    font-size: 14px;
    padding: 14px 30px;
    border-radius: 4px;
    font-weight: bold;
  }
  .dd-total {
    font-size: 20px;
  }
  .dd-info {
    font-weight: bold;
    color: rgba(17, 42, 99, 0.8);
  }

  .card-sub-name {
    color: rgba(17, 42, 99, 0.8);
    font-size: 21px;
    letter-spacing: 0;
    font-weight: bold;
  }
  .fake-class {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    text-transform: capitalize;
  }
  .card {
    background-color: #f9f9f9;
  }
  .data-details,
  .order-summary {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(171, 171, 171, 0.15);
    border: none;
  }
  .sub-header {
    background-color: #eeeeee;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sub-body {
    padding-bottom: 10px;
  }
  .sb-item span {
    font-size: 14px;
  }
  .sb-item {
    display: flex;
    padding-top: 8px;
  }
  .sub-body {
    background-color: #fff;
  }
  .sub-header h5 {
    color: #112a63;
  }
  .order-d-padding {
    padding-left: 40px;
  }
  .card-title {
    color: #061435;
    font-weight: bold;
  }
  .prod-qty,
  .input-bordered {
    width: 100px;
  }
  /* .editMode .data-head .data-col{
        width: fit-content;
    } */
  .btn {
    min-width: 0;
  }
  .fake-class span {
    text-transform: capitalize;
  }
  .c-footer {
    text-align: center;
  }
  .subtotal span {
    font-size: 14px;
    font-weight: bold;
  }
  thead .data-item {
    border-bottom: 1px solid #d2dde9;
  }
  thead th {
    padding-bottom: 12px !important;
  }
  .data-head .data-col {
    color: #495463;
    width: 20%;
  }
  .data-details-info.badge {
    width: fit-content !important;
  }
  .card-sub-title {
    font-size: 20px;
    color: #112a63;
  }
  .data-details-title {
    margin-top: 7px;
  }
  @media (max-width: 576px) {
    .container {
      padding-right: 0;
      padding-left: 0;
    }
  }
</style>
