import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./lang/index";
import lang from "./components/dashboard/layout/language";
import vSelect from "vue-select";
import "./registerServiceWorker";
import VueMoment from "vue-moment";

Vue.component("language", lang);
Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

Vue.use(VueMoment);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
