<template>
  <div class="page-content">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card content-area">
            <div class="card-innr" v-if="loaded">
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <router-link to="/customers" class="card-title">
                  <em class="ti ti-arrow-left mr-4"></em>
                  {{ orders[0].customerFirstName }}
                </router-link>
                <button class="btn btn-primary addO" @click="newOrder()">
                  <em class="ti ti-plus"></em>
                  {{ $t("new_order") }}
                </button>
              </div>

              <div class="table-responsive d-none d-md-block mt-3">
                <table class="data-table dt-init kyc-list" cellSpacing="0">
                  <thead>
                    <tr class="data-item data-head">
                      <th class="table-col dt-user">{{ $t("order_id") }}</th>
                      <th class="table-col">
                        {{ $t("price") }} ({{ user.country.currencySymbol }})
                      </th>
                      <th class="table-col">{{ $t("quantity") }}</th>
                      <th class="table-col">{{ $t("date") }}</th>
                      <th class="table-col">{{ $t("validated") }}</th>
                      <th class="table-col">{{ $t("payment") }}</th>
                      <th class="table-col">{{ $t("status") }}</th>
                      <th class="table-col">{{ $t("action") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="data-item"
                      v-for="order in pagination.current_data"
                      :key="order.order.id"
                    >
                      <td class="table-col dt-user">
                        <span class="sub sub-s2"
                          >#{{ order.order.orderNumber }}</span
                        >
                      </td>
                      <td class="table-col">
                        <span class="sub sub-s2"
                          >{{ user.country.currencySymbol }}
                          {{ formatPrice(order.order.orderTotalPrice) }}</span
                        >
                      </td>
                      <td class="table-col">
                        <span class="sub sub-s2">
                          {{ order.order.orderTotalQuantity }}
                        </span>
                      </td>
                      <td class="table-col">
                        <span class="sub sub-s2">
                          {{ new Date(order.order.orderDate).toDateString() }}
                        </span>
                      </td>
                      <td class="table-col">
                        <div class="table-flex">
                          <div
                            class="dot"
                            :class="
                              order.order.isValidated
                                ? 'dot-success'
                                : 'dot-danger'
                            "
                          ></div>
                          <span
                            class="sub sub-s2 cap"
                            :class="
                              order.order.isValidated
                                ? 'dot-t-success'
                                : 'dot-t-danger'
                            "
                            >{{ order.order.isValidated }}</span
                          >
                        </div>
                      </td>
                      <td class="table-col">
                        <div class="table-flex">
                          <div
                            class="dot"
                            :class="
                              order.order.orderPaymentStatus == 0
                                ? 'dot-danger'
                                : 'dot-success'
                            "
                          ></div>
                          <span
                            class="sub sub-s2 cap"
                            :class="
                              order.order.orderPaymentStatus == 0
                                ? 'dot-t-danger'
                                : 'dot-t-success'
                            "
                          >
                            {{
                              order.order.orderPaymentStatus == 0
                                ? $t("pending")
                                : $t("paid")
                            }}
                          </span>
                        </div>
                      </td>
                      <td class="table-col">
                        <div class="table-flex">
                          <div
                            class="dot"
                            :class="
                              order.order.orderStatus == 0
                                ? 'dot-warning'
                                : order.order.orderStatus == 1
                                ? 'dot-success'
                                : 'dot-danger'
                            "
                          ></div>
                          <span
                            class="sub sub-s2 cap"
                            :class="
                              order.order.orderStatus == 0
                                ? 'dot-t-warning'
                                : order.order.orderStatus == 1
                                ? 'dot-t-success'
                                : 'dot-t-danger'
                            "
                          >
                            {{
                              order.order.orderStatus == 0
                                ? $t("pending")
                                : order.order.orderStatus == 1
                                ? $t("delivered")
                                : $t("cancelled")
                            }}
                          </span>
                        </div>
                      </td>
                      <td class="table-col">
                        <router-link
                          :to="'/order/' + order.order.orderNumber"
                          class="btn btn-light-alt btn-xs btn-icon"
                          ><em class="ti ti-eye"></em
                        ></router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="m-recent-orders d-md-none d-block pl-0 pl-sm-3 pr-0 pr-sm-5"
              >
                <div
                  class="mro-item"
                  v-for="order in orders"
                  :key="order.order.id"
                >
                  <router-link
                    :to="'/order/' + order.order.orderNumber"
                    class="d-flex justify-content-between"
                  >
                    <div class="mro-left">
                      <div class="mrol-wrapper d-flex">
                        <div
                          class="mrol-icon"
                          :class="
                            order.order.orderStatus == 1 ? 'success' : 'pending'
                          "
                        >
                          <i class="fa fa-arrow-up"></i>
                        </div>

                        <div class="mrol-main ml-2">
                          <span class="order-id"
                            >ORDER ID: #{{ order.order.orderNumber }}</span
                          >
                          <p>
                            {{ new Date(order.order.orderDate).toDateString() }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="mro-right text-right">
                      <p
                        :class="
                          order.order.orderStatus == 1
                            ? 'price-success'
                            : 'price-pending'
                        "
                      >
                        {{ user.country.currencySymbol }}
                        {{ formatPrice(order.order.orderTotalPrice) }}
                      </p>
                      <span>{{
                        order.order.orderStatus == 0
                          ? $t("p_order")
                          : order.order.orderStatus == 1
                          ? $t("ds_order")
                          : $t("cs_order")
                      }}</span>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="row align-items-center pt-2">
                <div class="col-sm-12">
                  <div
                    class="dataTables_paginate paging_simple_numbers"
                    id="DataTables_Table_0_paginate"
                  >
                    <ul class="pagination">
                      <li
                        class="paginate_button page-item previous"
                        id="DataTables_Table_0_previous"
                        v-if="pagination.current_page != 1"
                      >
                        <a
                          href="#"
                          @click.prevent="
                            loadMore((pagination.current_page -= 2))
                          "
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="0"
                          tabindex="0"
                          class="page-link"
                          >Prev</a
                        >
                      </li>
                      <li
                        class="paginate_button page-item"
                        :class="
                          number == pagination.current_page ? 'active' : ''
                        "
                        v-for="(number, index) in pagination.data_length"
                        :key="index"
                      >
                        <a
                          href="javascript:void()"
                          @click="loadMore(index)"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="1"
                          tabindex="0"
                          class="page-link"
                          >{{ number }}</a
                        >
                      </li>
                      <li
                        class="paginate_button page-item next"
                        id="DataTables_Table_0_next"
                        v-if="pagination.current_page < pagination.data_length"
                      >
                        <a
                          href="javascript:void()"
                          @click.prevent="loadMore(pagination.current_page)"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="3"
                          tabindex="0"
                          class="page-link"
                        >
                          <div>Next</div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- .card-innr -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      user: (context) => context.user,
    }),
  },
  data() {
    return {
      orders: [],
      search: "",
      loaded: false,
      pagination: {
        current_page: 1,
        current_data: [],
        data_length: 0,
        per_page: 15,
      },
      subOrders: [],
      customer: {},
    };
  },
  methods: {
    newOrder() {
      // console.log(this.customer);
      this.$store.commit("setCustomer", this.customer);
      this.$router.push("/store");
    },
    searchOrder() {
      this.pagination.current_data = this.orders.filter((order) => {
        return (
          order.customer.firstName
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          order.customer.lastName
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          order.customer.phoneNumber
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          order.customer.outletName
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          order.order.orderTotalPrice == this.search ||
          order.order.orderNumber == this.search ||
          order.order.isValidated == this.search
        );
      });
      this.paginateData(this.pagination.current_data);
    },
    loadMore(index) {
      this.pagination.current_data = this.subOrders[index];
      this.pagination.current_page = ++index;
    },
    paginateData(data) {
      this.subOrders = [];
      for (var i = 0; i < data.length; i += this.pagination.per_page) {
        this.subOrders.push(data.slice(i, i + this.pagination.per_page));
      }
      this.pagination.data_length = this.subOrders.length;
      this.pagination.current_data = this.subOrders[0];
    },
    formatPrice(value) {
      return new Intl.NumberFormat().format(value);
    },
    getCustomerOrders() {
      this.$store
        .dispatch(
          "get",
          "services/app/Orders/GetHistory?CustomerId=" +
            this.$route.params.CustomerId
        )
        .then((resp) => {
          this.loaded = true;
          if (resp.data.success) {
            this.orders = resp.data.result;
            this.paginateData(this.orders);
          }
        });
    },
    getCustomer() {
      this.$store
        .dispatch(
          "get",
          "services/app/Customers/GetCustomerForView?id=" +
            this.$route.params.CustomerId
        )
        .then((resp) => {
          if (resp.data.success) {
            this.customer = resp.data.result;
          }
        });
    },
  },
  created() {
    this.getCustomer();
    this.getCustomerOrders();
  },
};
</script>

<style scoped>
.mro-item {
  box-shadow: 0px 1px 0px rgb(0 0 0 / 5%);
  padding: 10px 7px;
}
.card-title {
  font-size: 15px;
  font-weight: bold;
  color: #061435;
}
.table-col {
  color: rgba(0, 0, 0, 0.6);
}
table {
  border-collapse: separate;
  border-spacing: 0 5px;
}
.card {
  background: #f8f8f8;
}
@media (max-width: 576px) {
  .card-innr {
    padding-left: 0;
    padding-right: 0;
  }
  .btn.addO {
    min-width: 100px;
    padding: 5px 10px;
  }
}
</style>
