<template>
  <div class="page-content">
    <div class="container" v-if="loaded">
      <div class="card content-area">
        <div class="card-innr">
          <div
            class="card-head d-flex justify-content-between align-items-center"
          >
            <h4 class="card-title mb-0 cap">Workflow details</h4>
            <div
              v-if="
                (requisitions.stockRequestWorkflow.action == 0 ||
                  requisitions.stockRequestWorkflow.action == 4 ||
                  requisitions.stockRequestWorkflow.action == 5) &&
                requisitions.stockRequestWorkflow.status == 0
              "
            >
              <button
                class="btn btn-danger mx-1 header-btn"
                data-toggle="modal"
                data-target="#rejectRequest"
              >
                <i class="ti ti-close"></i>
                Reject
              </button>
              <button
                class="btn btn-success header-btn header-btn"
                data-toggle="modal"
                data-target="#acceptRequest"
              >
                <i class="ti ti-check"></i>
                Approve
              </button>
            </div>
            <button
              v-if="
                requisitions.stockRequestWorkflow.action == 1 &&
                requisitions.stockRequestWorkflow.status == 0
              "
              class="btn btn-success header-btn"
              data-toggle="modal"
              data-target="#issueRequest"
            >
              <i class="ti ti-check"></i> Issue Request
            </button>
            <div
              v-if="
                requisitions.stockRequestWorkflow.action == 2 &&
                requisitions.stockRequestWorkflow.status == 0
              "
            >
              <button
                class="btn btn-danger header-btn mr-1"
                data-toggle="modal"
                data-target="#cancelReconciliation"
              >
                <i class="ti ti-times"></i> Decline
              </button>
              <button
                class="btn btn-success header-btn"
                data-toggle="modal"
                data-target="#reconcileRequest"
              >
                <i class="ti ti-check"></i> Reconcile Request
              </button>
            </div>

            <button
              v-if="
                requisitions.stockRequestWorkflow.action == 3 &&
                requisitions.stockRequestWorkflow.status == 0
              "
              class="btn btn-success header-btn"
              data-toggle="modal"
              data-target="#closeRequest"
            >
              <i class="ti ti-check"></i> Close Request
            </button>
          </div>
          <div class="viewMode">
            <div class="row">
              <div class="col-12">
                <div class="card content-area mb-1">
                  <div class="data-details px-1">
                    <div class="req-summary px-3">
                      <div class="fake-class">
                        <span class="data-details-title">Request Number:</span>
                        <span class="data-details-info dd-info">{{
                          requisitions.stockRequest.stockRequest.requestNumber
                        }}</span>
                      </div>
                      <div class="fake-class">
                        <span class="data-details-title">Requester:</span>
                        <span class="data-details-info dd-info">{{
                          requisitions.stockRequest.requestedByName
                        }}</span>
                      </div>
                      <div class="fake-class">
                        <span class="data-details-title">
                          {{ $t("o_date") }}:</span
                        >
                        <span class="data-details-info dd-info">{{
                          new Date(
                            requisitions.stockRequest.stockRequest.requestDate
                          ).toDateString()
                        }}</span>
                      </div>
                      <div class="fake-class">
                        <span class="data-details-title"> Total:</span>
                        <span class="data-details-info dd-info"
                          >{{ user.country.currencySymbol
                          }}{{ new Intl.NumberFormat().format(total) }}</span
                        >
                      </div>
                      <div class="fake-class">
                        <span class="data-details-title"
                          >{{ $t("o_status") }}:</span
                        >

                        <span
                          v-if="
                            requisitions.stockRequest.stockRequest.status == 0
                          "
                          class="data-details-info os os-primary"
                          >Draft</span
                        >

                        <span
                          v-if="
                            requisitions.stockRequest.stockRequest.status == 1
                          "
                          class="data-details-info os os-success"
                          >Submitted</span
                        >

                        <span
                          v-if="
                            requisitions.stockRequest.stockRequest.status == 2
                          "
                          class="data-details-info os os-danger"
                          >Rejected</span
                        >

                        <span
                          v-if="
                            requisitions.stockRequest.stockRequest.status == 3
                          "
                          class="data-details-info os os-success"
                          >Approved</span
                        >

                        <span
                          v-if="
                            requisitions.stockRequest.stockRequest.status == 4
                          "
                          class="data-details-info os os-secondary"
                          >Issued</span
                        >

                        <span
                          v-if="
                            requisitions.stockRequest.stockRequest.status == 5
                          "
                          class="data-details-info os os-success"
                          >Reconciled</span
                        >

                        <span
                          v-if="
                            requisitions.stockRequest.stockRequest.status == 6
                          "
                          class="data-details-info os os-success"
                          >Closed</span
                        >
                        <span
                          v-if="
                            requisitions.stockRequest.stockRequest.status == 7
                          "
                          class="data-details-info os os-success"
                          >Approved2</span
                        >
                        <span
                          v-if="
                            requisitions.stockRequest.stockRequest.status == 8
                          "
                          class="data-details-info os os-danger"
                          >Rejected2</span
                        >
                        <span
                          v-if="
                            requisitions.stockRequest.stockRequest.status == 9
                          "
                          class="data-details-info os os-danger"
                          >Cancelled</span
                        >
                        <span
                          v-if="
                            requisitions.stockRequest.stockRequest.status == 10
                          "
                          class="data-details-info os os-success"
                          >Approved3</span
                        >
                        <span
                          v-if="
                            requisitions.stockRequest.stockRequest.status == 11
                          "
                          class="data-details-info os os-danger"
                          >Rejected3</span
                        >
                      </div>
                    </div>
                    <div class="table-responsive mt-5">
                      <table class="data-table table-striped">
                        <thead>
                          <tr class="data-item data-head">
                            <th class="data-col text-left" style="width: 30%">
                              {{ $t("product") }}
                            </th>
                            <th class="data-col">{{ $t("unit") }}</th>
                            <th class="data-col">Unit price</th>
                            <th class="data-col">Quantity Requested</th>
                            <th class="data-col">Quantity Issued</th>
                            <th class="data-col">Quantity Returned</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="data-item"
                            v-for="(ord, index) in requisitions.stockRequest
                              .stockRequestDetails"
                            :key="index"
                          >
                            <td class="data-col text-left pl-3">
                              <span class="sub sub-s2">{{
                                ord.productName
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.productUnitName
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">
                                {{ user.country.currencySymbol }}
                                {{
                                  formatPrice(ord.stockRequestDetail.unitPrice)
                                }}</span
                              >
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.stockRequestDetail.quantityRequested
                              }}</span>
                            </td>
                            <td class="data-col">
                              <!-- <input
                                type="number"
                                class="form-control"
                                v-if="
                                  requisitions.stockRequestWorkflow.action ==
                                    1 &&
                                  requisitions.stockRequestWorkflow.status == 0
                                "
                                v-model="ord.stockRequestDetail.quantityIssued"
                              /> -->
                              <span class="sub sub-s2">{{
                                ord.stockRequestDetail.quantityIssued
                              }}</span>
                            </td>
                            <td class="data-col">
                              <input
                                type="number"
                                class="form-control"
                                v-if="
                                  requisitions.stockRequestWorkflow.action ==
                                    2 &&
                                  requisitions.stockRequestWorkflow.status == 0
                                "
                                v-model="
                                  ord.stockRequestDetail.quantityReturned
                                "
                              />
                              <span class="sub sub-s2" v-else>{{
                                ord.stockRequestDetail.quantityReturned
                              }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="viewMode">
            <div class="row">
              <div class="col-12">
                <h2 class="card-title mb-2 mt-4 font-weight-bold">
                  Workflow Trail
                </h2>
                <div class="card content-area order-summary">
                  <div class="card-innr px-0">
                    <div class="table-responsive">
                      <table class="data-table table-striped">
                        <thead>
                          <tr class="data-item data-head">
                            <th class="data-col text-left">{{ $t("date") }}</th>
                            <th class="data-col">Personnel</th>
                            <th class="data-col">Territory</th>
                            <th class="data-col">Action Required</th>
                            <th class="data-col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="data-item"
                            v-for="(ord, index) in requisitions.stockRequest
                              .workflowTrail"
                            :key="index"
                          >
                            <td class="data-col text-left pl-3">
                              <span class="sub sub-s2">{{
                                new Date(
                                  ord.stockRequestWorkflow.processedDate
                                ).toDateString()
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.processedByName
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span class="sub sub-s2">{{
                                ord.processorTerritoryName
                              }}</span>
                            </td>
                            <td class="data-col">
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.action == 0"
                                >Approve/Reject</span
                              >
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.action == 1"
                                >Issue</span
                              >
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.action == 2"
                                >Reconcile</span
                              >
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.action == 3"
                                >Close</span
                              >
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.action == 4"
                              >
                                Approve/Reject2</span
                              >
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.action == 5"
                              >
                                Approve/Reject3</span
                              >
                            </td>
                            <td class="data-col">
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.status == 0"
                              >
                                Pending
                              </span>
                              <span
                                class="sub sub-s2"
                                v-if="ord.stockRequestWorkflow.status == 1"
                              >
                                Completed
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="viewMode mt-4">
            <h2 class="card-title mb-4 font-weight-bold">Documents</h2>
            <div class="row">
              <div
                class="col-sm-4"
                v-for="(item, key) in requisitions.stockRequest
                  .stockRequestFiles"
                :key="key"
              >
                <div class="card">
                  <a :href="item.fileURL">
                    <p class="card-text text-center">
                      {{ item.fileDescription }}
                      {{
                        item.fileType.includes("image") ? "(Image)" : "(PDF)"
                      }}
                    </p>
                    <div
                      class="docu-file"
                      v-if="item.fileType.includes('image')"
                    >
                      <img :src="item.fileURL" class="w-100 h-100" />
                    </div>
                    <div v-else>
                      <pdf :src="item.fileURL"></pdf>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="cancelReconciliation">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Decline Reconciliation</h6>
          </div>
          <a
            href="#"
            class="modal-close"
            data-dismiss="modal"
            aria-label="Close"
            ><em class="ti ti-close"></em
          ></a>
          <div class="modal-body">
            <form>
              <div class="form-group mb-0">
                <label> Comment </label>
                <div class="input-item">
                  <textarea
                    class="form-control"
                    id=""
                    cols="30"
                    rows="5"
                    v-model="comment"
                  ></textarea>
                </div>
              </div>
              <button
                class="btn btn-success"
                :disabled="loading"
                @click.prevent="cancelReconciliation()"
              >
                <span v-if="!loading">Decline</span>
                <span v-else>Loading...</span>
              </button>
            </form>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>
    <div class="modal fade" id="rejectRequest">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Reject Request</h6>
          </div>
          <a
            href="#"
            class="modal-close"
            data-dismiss="modal"
            aria-label="Close"
            ><em class="ti ti-close"></em
          ></a>
          <div class="modal-body">
            <form>
              <div class="form-group mb-0">
                <label> Comment </label>
                <div class="input-item">
                  <textarea
                    class="form-control"
                    id=""
                    cols="30"
                    rows="5"
                    v-model="comment"
                  ></textarea>
                </div>
              </div>
              <button
                class="btn btn-success"
                :disabled="loading"
                @click.prevent="rejectRequest()"
              >
                <span v-if="!loading">Reject Request</span>
                <span v-else>Loading...</span>
              </button>
            </form>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>
    <div class="modal fade" id="acceptRequest">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Accept Request</h6>
          </div>
          <a
            href="#"
            class="modal-close"
            data-dismiss="modal"
            aria-label="Close"
            ><em class="ti ti-close"></em
          ></a>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="">Comment</label>
                <textarea
                  v-model="comment"
                  class="form-control"
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
              <button
                class="btn btn-success"
                :disabled="loading"
                @click.prevent="approveRequest()"
              >
                <span v-if="!loading">Accept Request</span>
                <span v-else>Loading...</span>
              </button>
            </form>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>
    <div class="modal fade" id="issueRequest">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Issue Request</h6>
          </div>
          <a
            href="#"
            class="modal-close"
            data-dismiss="modal"
            aria-label="Close"
            ><em class="ti ti-close"></em
          ></a>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="">Comment</label>
                <textarea
                  v-model="comment"
                  class="form-control"
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
              <button
                class="btn btn-success"
                :disabled="loading"
                @click.prevent="issueRequest()"
              >
                <span v-if="!loading">Issue Stock</span>
                <span v-else>Loading...</span>
              </button>
            </form>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>
    <div class="modal fade" id="reconcileRequest">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Reconcile Request</h6>
          </div>
          <a
            href="#"
            class="modal-close"
            data-dismiss="modal"
            aria-label="Close"
            ><em class="ti ti-close"></em
          ></a>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="">Comment</label>
                <textarea
                  v-model="comment"
                  class="form-control"
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
              <button
                class="btn btn-success"
                :disabled="loading"
                @click.prevent="reconcileRequest()"
              >
                <span v-if="!loading">Reconcile Request</span>
                <span v-else>Loading...</span>
              </button>
            </form>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>
    <div class="modal fade" id="closeRequest">
      <div class="modal-dialog modal-dialog-sm modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Close Request</h6>
          </div>
          <a
            href="#"
            class="modal-close"
            data-dismiss="modal"
            aria-label="Close"
            ><em class="ti ti-close"></em
          ></a>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="">Comment</label>
                <textarea
                  v-model="comment"
                  class="form-control"
                  cols="30"
                  rows="4"
                ></textarea>
              </div>
              <button
                class="btn btn-success"
                :disabled="loading"
                @click.prevent="closeRequest()"
              >
                <span v-if="!loading">Close Request</span>
                <span v-else>Loading...</span>
              </button>
            </form>
          </div>
        </div>
        <!-- .modal-content -->
      </div>
      <!-- .modal-dialog -->
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import pdf from "vue-pdf";
  export default {
    components: {
      pdf,
    },
    computed: {
      ...mapState({
        user: (context) => context.user,
      }),
    },
    data() {
      return {
        selected: true,
        loaded: false,
        requisitions: [],
        ready: false,
        loading: false,
        comment: "",
        total: 0,
      };
    },
    methods: {
      formatPrice(value) {
        return new Intl.NumberFormat().format(value);
      },
      getStockRequest() {
        this.$store
          .dispatch(
            "get",
            "services/app/StockRequests/GetStockRequestWorkflow?workflowId=" +
              this.$route.params.id
          )
          .then((resp) => {
            this.loaded = true;
            // console.log(resp);
            if (resp.data.success) {
              this.requisitions = resp.data.result;
              if (
                this.requisitions.stockRequestWorkflow.action == 1 &&
                this.requisitions.stockRequestWorkflow.status == 0
              ) {
                this.requisitions.stockRequest.stockRequestDetails.forEach(
                  (item) => {
                    item.stockRequestDetail.quantityIssued =
                      item.stockRequestDetail.quantityRequested;
                  }
                );
              }
              this.requisitions.stockRequest.stockRequestDetails.forEach(
                (element) => {
                  this.total +=
                    element.stockRequestDetail.quantityRequested *
                    element.stockRequestDetail.unitPrice;
                }
              );
            }
          });
      },
      approveRequest() {
        this.loading = true;
        var endpoint = "services/app/StockRequests/ApproveRequest";
        if (this.requisitions.stockRequestWorkflow.action == 4) {
          endpoint = "services/app/StockRequests/ApproveRequest2";
        }
        if (this.requisitions.stockRequestWorkflow.action == 5) {
          endpoint = "services/app/StockRequests/ApproveRequest3";
        }
        this.$store
          .dispatch("post", {
            endpoint: `${endpoint}?approverId=${this.user.agent.id}&workflowId=${this.$route.params.id}&comment=${this.comment},`,
            details: {},
          })
          .then((resp) => {
            this.loading = false;
            if (resp.data.success) {
              window.location = "/workflows";
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      rejectRequest() {
        this.loading = true;
        var endpoint = "services/app/StockRequests/RejectRequest";
        if (this.requisitions.stockRequestWorkflow.action == 4) {
          endpoint = "services/app/StockRequests/RejectRequest2";
        }
        if (this.requisitions.stockRequestWorkflow.action == 5) {
          endpoint = "services/app/StockRequests/RejectRequest3";
        }
        this.$store
          .dispatch("post", {
            endpoint: `${endpoint}?approverId=${this.user.agent.id}&workflowId=${this.$route.params.id}&comment=${this.comment},`,
            details: {},
          })
          .then((resp) => {
            this.loading = false;
            if (resp.data.success) {
              window.location = "/workflows";
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      issueRequest() {
        var issuedStock = [];
        this.loading = true;
        this.requisitions.stockRequest.stockRequestDetails.forEach((item) => {
          issuedStock.push({
            stockRequestDetailId: item.stockRequestDetail.id,
            productId: item.stockRequestDetail.productId,
            productUnitId: item.stockRequestDetail.productUnitId,
            quantityIssued: item.stockRequestDetail.quantityIssued,
          });
        });
        this.$store
          .dispatch("post", {
            endpoint: "services/app/StockRequests/IssueStock",
            details: {
              issuerId: this.user.agent.id,
              workflowId: this.$route.params.id,
              comment: this.comment,
              issuedStock: issuedStock,
            },
          })
          .then((resp) => {
            this.loading = false;
            if (resp.data.success) {
              window.location = "/workflows";
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      cancelReconciliation() {
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: `services/app/StockRequests/CancelReconciliation?agentId=${this.user.agent.id}&workflowId=${this.$route.params.id}&comment=${this.comment},`,
            details: {},
          })
          .then((resp) => {
            this.loading = false;
            console.log(resp);
            window.location.reload();
            // if (resp.data.success) {
            //   window.location = "/workflows";
            // }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      reconcileRequest() {
        this.loading = true;
        var reconciledStock = [];
        this.requisitions.stockRequest.stockRequestDetails.forEach((item) => {
          reconciledStock.push({
            stockRequestDetailId: item.stockRequestDetail.id,
            productId: item.stockRequestDetail.productId,
            productUnitId: item.stockRequestDetail.productUnitId,
            quantityReturned: item.stockRequestDetail.quantityReturned,
          });
        });
        this.$store
          .dispatch("post", {
            endpoint: "services/app/StockRequests/ReconcileRequest",
            details: {
              reconcilerId: this.user.agent.id,
              workflowId: this.$route.params.id,
              comment: this.comment,
              reconciledStock: reconciledStock,
            },
          })
          .then((resp) => {
            this.loading = false;
            if (resp.data.success) {
              window.location = "/workflows";
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      closeRequest() {
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: `services/app/StockRequests/CloseRequest`,
            details: {
              closerId: this.user.agent.id,
              workflowId: this.$route.params.id,
              comment: this.comment,
            },
          })
          .then((resp) => {
            this.loading = false;
            if (resp.data.success) {
              window.location = "/workflows";
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
    },
    created() {
      this.getStockRequest();
      console.log(this.user);
    },
  };
</script>

<style scoped>
  .acceptR i {
    font-size: 40px;
    color: green;
  }
  .editMode .data-col {
    text-align: center;
  }
  .btn-info {
    background: #00738c;
    border: none;
  }
  .btn-success {
    background: #03a500 !important;
  }
  .down-btn .btn-sm {
    padding: 10px 18px;
    border-radius: 3px;
    font-family: plus_jakarta_displayregular;
    font-weight: bold;
  }
  .down-btn em {
    font-size: 20px;
  }

  .btn-danger {
    background: #ff0000;
  }
  .or-kil {
    font-weight: normal !important;
    font-size: 15px !important;
    font-family: plus_jakarta_displayregular;
  }
  .or-subt {
    color: rgba(17, 42, 99, 0.8);
  }

  .data-table:not(.dt-init) .data-item .data-col {
    padding-bottom: 15px;
    text-align: center;
  }
  .down-btn {
    margin-right: 10px;
  }
  .total-amt {
    font-size: 14px;
    padding: 14px 30px;
    border-radius: 4px;
    font-weight: bold;
  }
  .dd-total {
    font-size: 20px;
  }
  .dd-info {
    font-weight: bold;
    color: rgba(17, 42, 99, 0.8);
  }
  .card-sub-name {
    color: rgba(17, 42, 99, 0.8);
    font-size: 21px;
    letter-spacing: 0;
    font-weight: bold;
  }
  .fake-class {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    text-transform: capitalize;
  }
  .card {
    background-color: #f9f9f9;
  }
  .data-details,
  .order-summary {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(171, 171, 171, 0.15);
    border: none;
  }
  .sub-header {
    background-color: #eeeeee;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sub-body {
    padding-bottom: 10px;
  }
  .sb-item span {
    font-size: 14px;
  }
  .sb-item {
    display: flex;
    padding-top: 8px;
  }
  .sub-body {
    background-color: #fff;
  }
  .sub-header h5 {
    color: #112a63;
  }
  .order-d-padding {
    padding-left: 40px;
  }
  .card-title {
    color: #061435;
    font-weight: bold;
  }
  .prod-qty,
  .input-bordered {
    width: 100px;
  }
  /* .editMode .data-head .data-col{
        width: fit-content;
    } */
  .btn {
    min-width: 0;
  }
  .fake-class span {
    text-transform: capitalize;
  }
  .c-footer {
    text-align: center;
  }
  .subtotal span {
    font-size: 14px;
    font-weight: bold;
  }
  thead .data-item {
    border-bottom: 1px solid #d2dde9;
  }
  thead th {
    padding-bottom: 12px !important;
  }
  .data-head .data-col {
    color: #495463;
    width: 20%;
  }
  .data-details-info.badge {
    width: fit-content !important;
  }
  .card-sub-title {
    font-size: 20px;
    color: #112a63;
  }
  .data-details-title {
    margin-top: 7px;
  }
  @media (max-width: 576px) {
    .container {
      padding-right: 0;
      padding-left: 0;
    }
  }
</style>
