<template>
  <div class="page-ath-wrap d-flex justify-content-center align-items-center">
    <div class="page-ath-content">
      <div class="page-ath-header d-flex justify-content-center">
        <a href="./" class="page-ath-logo"
          ><img src="../assets/images/logo.png" alt="logo"
        /></a>
      </div>
      <div class="page-ath-form pb-3">
        <form @submit.prevent="getToken()">
          <div class="input-item input-with-label">
            <div class="relative">
              <span class="input-icon input-icon-left"
                ><em class="ti ti-world"></em
              ></span>
              <div class="select-wrapper">
                <select
                  @change="getCountryCode($event)"
                  class="input-bordered required valid s-drop"
                  data-msg="Required"
                  id="nationality"
                  aria-required="true"
                  aria-invalid="false"
                >
                  <option value=""></option>
                  <option
                    :selected="
                      code.tenantName == ipCountry ? selected : !selected
                    "
                    v-for="code in data"
                    :key="code.tenantId"
                    :value="code.tenantId"
                  >
                    {{ code.tenantName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="input-item input-with-label">
            <div class="relative">
              <span class="input-icon input-icon-left"
                ><em class="fa fa-user"></em
              ></span>
              <input
                class="input-bordered"
                v-model="username"
                type="text"
                :placeholder="$t('username')"
              />
            </div>
          </div>
          <div class="input-item input-with-label">
            <div class="relative">
              <span class="input-icon input-icon-left"
                ><em class="fa fa-lock"></em
              ></span>
              <input
                class="input-bordered"
                v-model="password"
                type="password"
                :placeholder="$t('password')"
              />
            </div>
          </div>
          <button class="btn btn-primary btn-block" type="submit">
            <span v-if="!loading">{{ $t("login") }}</span>
            <span v-else>{{ $t("loading") }}</span>
          </button>
        </form>
      </div>
      <div class="d-flex justify-content-center pb-4">
        <language />
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import language from "./dashboard/layout/language.vue";
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState({
        userState: (context) => context.user,
      }),
    },
    components: { language },
    data() {
      return {
        data: [],
        user: [],
        country_code: "",
        username: "",
        password: "",
        token: "",
        loading: false,
        country: {},
        ipCountry: "",
        loaded: false,
        phone: "",
        selected: true,
      };
    },
    methods: {
      getCountryCode(event) {
        const talentId = event.target.value;

        this.data.forEach((element) => {
          if (element.tenantId == talentId) {
            this.country = element;
            this.country_code = element.urnoPrefix;
          }
        });
      },
      login() {
        this.$store
          .dispatch(
            "get",
            "services/app/Agents/GetAgentByEmail?email=" + this.username
          )
          .then((resp) => {
            this.loading = false;
            console.log(resp);
            if (resp.data.result) {
              var data = resp.data.result;
              data.token = this.$store.state.token;
              data.country = this.country;
              this.$store.commit("setUser", data);
              this.$router.push("/dashboard");
            } else {
              window.ToasterAlert("error", "Invalid username and password");
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$store.dispatch("handleError", error);
          });
      },
      getToken() {
        if (this.userState) {
          if (this.userState.token) {
            this.$router.push("/dashboard");
          }
        } else {
          if (this.username.length < 1) {
            window.ToasterAlert("error", "Username field is required");
            return false;
          }
          if (this.password.length < 1) {
            window.ToasterAlert("error", "Password field is required");
            return false;
          }
          this.loading = true;
          this.$store
            .dispatch("post", {
              endpoint: "TokenAuth/Authenticate",
              details: {
                userNameOrEmailAddress: this.username,
                password: this.password,
                tenantId: this.country.tenantId,
              },
            })
            .then((resp) => {
              if (resp.status == 200) {
                this.token = resp.data.result.accessToken;
                this.$store.commit("setToken", this.token);
                // console.log(resp)
                this.login();
              }
            })
            .catch((error) => {
              this.$store.dispatch("handleError", error);
            });
        }
      },
      getMarket() {
        this.$store
          .dispatch("get", "services/app/EndMarkets/GetEndMarkets")
          .then((resp) => {
            if (resp.status == 200) {
              this.data = resp.data.result;
              this.getIp();
            }
          })
          .catch((error) => {
            this.$store.dispatch("handleError", error);
          });
      },
      getIp() {
        axios
          .get(
            "https://api.bigdatacloud.net/data/ip-geolocation?key=6e359050e938481b9853b2a7ca512bde"
          )
          .then((resp) => {
            this.ipCountry = resp.data.country.name;
            this.data.forEach((item) => {
              if (item.tenantName == resp.data.country.name) {
                this.country_code = item.urnoPrefix;
                this.country = item;
              }
              // if(item.urnoPrefix == this.country_code){
              //     this.country = item
              //     console.log(this.country)
              // }
            });
          });
      },
    },
    created() {
      this.$store.commit("getUser");
      this.getMarket();
    },
  };
</script>
<style scoped>
  .page-ath-content {
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
      0 15px 40px rgba(166, 173, 201, 0.2);
  }
  label {
    line-height: 19px !important;
  }
  .s-drop {
    padding-left: 35px;
  }
  @media (min-width: 576px) {
    .s-drop {
      padding-left: 50px;
    }
  }
</style>
