<template>
  <div class="page-content">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card content-area">
            <h6 class="card-title pl-2 pl-sm-3 pr-2 pr-sm-5 mt-3 mb-0">
              My Requests
            </h6>
            <div class="card-innr pt-2">
              <div v-if="orders.length > 0">
                <div class="d-none d-md-block">
                  <div class="table-responsive">
                    <table class="data-table dt-init kyc-list table-striped">
                      <thead>
                        <tr class="data-item data-head">
                          <th class="table-col">{{ $t("id") }}</th>
                          <th class="table-col">{{ $t("Date") }}</th>
                          <th class="table-col">{{ $t("agent") }}</th>
                          <th class="table-col">{{ $t("items") }}</th>
                          <th class="table-col dt-status">
                            {{ $t("status") }}
                          </th>
                          <th class="table-col">{{ $t("action") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="data-item"
                          v-for="order in orders.slice(0, 5)"
                          :key="order.id"
                        >
                          <td class="table-col">
                            <span class="sub sub-s2">{{
                              order.orderNumber
                            }}</span>
                          </td>
                          <td class="table-col">
                            <span class="sub sub-s2">
                              {{ new Date(order.orderDate).toDateString() }}
                            </span>
                          </td>

                          <td class="table-col">
                            <span class="sub sub-s2">{{
                              order.customer.firstName +
                              " " +
                              order.customer.lastName
                            }}</span>
                          </td>
                          <td>
                            <span class="sub sub-s2">2</span>
                          </td>
                          <td class="table-col">
                            <div class="table-flex">
                              <div
                                class="dot"
                                :class="
                                  order.orderStatus == 0
                                    ? 'dot-warning'
                                    : order.orderStatus == 1
                                    ? 'dot-success'
                                    : 'dot-danger'
                                "
                              ></div>
                              <span
                                class="sub sub-s2"
                                :class="
                                  order.orderStatus == 0
                                    ? 'dot-t-warning'
                                    : order.orderStatus == 1
                                    ? 'dot-t-success'
                                    : 'dot-t-danger'
                                "
                                >{{
                                  order.orderStatus == 0
                                    ? $t("pending")
                                    : order.orderStatus == 1
                                    ? $t("approaved")
                                    : $t("cancelled")
                                }}</span
                              >
                            </div>
                          </td>
                          <td class="table-col text-center table-action">
                            <router-link
                              :to="'/requisition/view/' + order.id"
                              class="eye"
                              ><em class="ti ti-eye"></em
                            ></router-link>
                            <!-- <div class="d-flex align-items-center">
                              
                              <button
                                class="btn btn-danger mx-1"
                                data-toggle="modal"
                                data-target="#rejectRequest"
                              >
                                <i class="ti ti-close"></i>
                              </button>
                              <button
                                class="btn btn-success"
                                data-toggle="modal"
                                data-target="#acceptRequest"
                              >
                                <i class="ti ti-check"></i>
                              </button>
                            </div> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="m-recent-orders d-md-none d-block">
                  <div
                    class="mro-item"
                    v-for="order in orders.slice(0, 5)"
                    :key="order.id"
                  >
                    <router-link
                      :to="'/requisition/view/' + order.id"
                      class="d-flex justify-content-between"
                    >
                      <div class="mro-left">
                        <div class="mrol-wrapper d-flex">
                          <div
                            class="mrol-icon"
                            :class="
                              order.orderStatus == 1 ? 'success' : 'pending'
                            "
                          >
                            <i class="fa fa-arrow-up"></i>
                          </div>

                          <div class="mrol-main ml-2">
                            <p>{{ order.customer.firstName }}</p>
                            <span class="order-id"
                              >ORDER ID: {{ order.orderNumber }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="mro-right text-right">
                        <!-- <p>
                          {{ user.country.currencySymbol }}
                          {{ formatPrice(order.orderTotalPrice) }}
                        </p> -->
                        <span>{{
                          order.orderStatus == 0
                            ? $t("pending")
                            : order.orderStatus == 1
                            ? "approaved"
                            : $t("cancelled")
                        }}</span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div
                class="text-center text-dark font-weight-bold alert alert-info"
                v-else
              >
                <p>No recent orders</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      user: (context) => context.user,
    }),
  },
  data() {
    return {
      orders: [
        {
          id: "1002",
          orderDate: "2022-08-17T10:29:27.449",
          orderNumber: "09440",
          customer: {
            firstName: "dellins",
            lastName: "Oyedele",
          },
          orderStatus: 1,
        },
      ],
    };
  },
};
</script>

<style scoped>
.card-title {
  color: black;
  font-weight: bold;
  font-size: 19px;
}
</style>
