<template>
  <div class="page-content">
    <div class="container">
      <h6 class="card-title">Feeds</h6>
      <div class="row mt-3">
        <!-- <div class="col-12">
          <div class="card">
            <video
              src="https://stagingretailmarket.blob.core.windows.net/assets/BAT-VID2.mp4"
              controls
              controlsList="nodownload"
              class="card-img-top"
            ></video>
            <div class="card-body p-0 pt-2 pb-2">
              <h5>Bumpa rewards</h5>
              <span>1 hour</span>
            </div>
          </div>
        </div> -->
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              Hello. BAT is offering you an opportunity to win N10k voucher !!!
              All you have to do is very simple … PLAY the engagement video on
              your mobile trader device to your customers in outlets with the
              blue Project Cheetah poster and tell them to ask the smokers who
              buy cigarettes in their outlets to follow the prompt on the QR
              code or USSD to register. Don't forget, your customer can also win
              N50k or 1 roll of Rothmans oo!
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card">
            <div class="card-body">
              Hello, your customers have a chance to win either N50K or a roll
              of Rothmans when consumers who buy cigarettes in their outlets
              register through the USSD or QR code. Play the project cheetah
              video to your customers and you also could stand a chance to win
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card video-card">
            <video
              src="https://stagingretailmarket.blob.core.windows.net/assets/BAT-VID1.mp4"
              controls
              controlsList="nodownload"
              class="card-img-top"
            ></video>
            <div class="card-body p-0 pt-2 pb-2">
              <!-- <h5>Bumpa rewards</h5> -->
              <span>1 hour</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.card-body h5 {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0;
}
.video-card {
  background: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.card {
  border-radius: 10px;

  /* border-bottom: 1px solid #e0e0e0; */
}
.card-img-top {
  border-radius: 10px;
}
@media (min-width: 1200px) {
  .container {
    max-width: 700px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 649px;
  }
}
</style>
