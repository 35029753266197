import Vue from "vue";
import VueRouter from "vue-router";
import login from "../components/login";
import layout from "../components/dashboard/layout";
import dashboard from "../components/dashboard/dashboard";
import orders from "../components/dashboard/orders";
import order from "../components/dashboard/order";
import checkout from "../components/dashboard/checkout";
import products from "../components/dashboard/products";
import customers from "../components/dashboard/customer/customers";
import customer from "../components/dashboard/customer/customer";
import customerEdit from "../components/dashboard/customer/customerEdit";
import journeyPlans from "../components/dashboard/journeyPlan/index";
import StockRequests from "../components/dashboard/requisition/stockRequests";
import StockRequest from "../components/dashboard/requisition/stockRequest";
import CreateRequest from "../components/dashboard/requisition/create";
import MyRequests from "../components/dashboard/requisition/myrequests";
import Workflows from "../components/dashboard/requisition/workflows";
import Workflow from "../components/dashboard/requisition/workflow";
import Newsfeeds from "../components/dashboard/news/newsfeeds";
import Password from "../components/dashboard/password";
import Profile from "../components/dashboard/profile";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: login, name: "login" },
  { path: "/login", component: login, name: "login" },
  {
    path: "/",
    component: layout,
    children: [
      { path: "/dashboard", component: dashboard, name: "dashboard" },
      { path: "/orders", component: orders, name: "orders" },
      { path: "/order/:id", component: order, name: "order" },
      { path: "/checkout", component: checkout, name: "checkout" },
      { path: "/store", component: products, name: "products" },
      { path: "/profile", component: Profile, name: "profile" },
      { path: "/customers", component: customers, name: "customers" },
      { path: "/customer/:CustomerId", component: customer, name: "customer" },
      { path: "/newsfeeds", component: Newsfeeds, name: "newsfeeds" },
      {
        path: "/customer/:CustomerId/edit",
        component: customerEdit,
        name: "customerEdit",
      },
      { path: "/journey-plans", component: journeyPlans, name: "journeyPlans" },
      {
        path: "/stock-requests",
        component: StockRequests,
        name: "stockRequests",
      },
      {
        path: "/stock-request/view/:id",
        component: StockRequest,
        name: "stockRequest",
      },
      {
        path: "/stock-request/create",
        component: CreateRequest,
        name: "CreateRequest",
      },
      {
        path: "/workflows",
        component: Workflows,
        name: "workflows",
      },
      {
        path: "/workflow/view/:id",
        component: Workflow,
        name: "workflow",
      },
      {
        path: "/requisition/my-requests",
        component: MyRequests,
        name: "MyRequests",
      },
      {
        path: "/password",
        component: Password,
        name: "password",
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
