<template>
    <div class="page-content" v-if="loaded">
        <div class="container">
            <div class="card content-area">
                <div class="card-innr">
                    <!-- <div class="card-head">
                        <h4 class="card-title">Journey Plan</h4>
                    </div> -->
                    <!-- <data-table 
                        :title="$t('journey_plan')"
                        :columns='columns'
                        :rows='rows' 
                        :table-classes='"data-table dt-init kyc-list"'
                        style="text-transform:uppercase"
                    /> -->
                    <div class="table-responsive">
                        <table class="data-table dt-init kyc-list" id="myTable" cellSpacing="0">
                            <thead>
                                <!-- <tr class="data-item data-head">
                                    <th class="table-col">Name</th>
                                    <th class="table-col">Phone</th>
                                    <th class="table-col">URNO</th>
                                    <th class="table-col">Day</th>
                                </tr> -->
                                <tr class="data-item data-head ">
                                    <th class="data-col">
                                        <span>Name</span>
                                        <!-- <input type="text" class="form-control" v-model="search.name" @input="searchPlan()"> -->
                                    </th>
                                    <th class="data-col">
                                        <span>Phone</span>
                                        <!-- <input type="text" class="form-control" v-model="search.phone" @input="searchPlan()"> -->
                                    </th>
                                    <th class="data-col">
                                        <span>URNO</span>
                                        <!-- <input type="text" class="form-control" v-model="search.urno" @input="searchPlan()"> -->
                                    </th>
                                    <th class="data-col">
                                        <span>Day</span>
                                        <!-- <input type="text" class="form-control" v-model="search.day" @input="searchPlan()"> -->
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="data-item" v-for="(plan,index) in pagination.current_data" :key="index">
                                    <td class="table-col">
                                        <span class="sub sub-s2">{{plan.customerName}}</span>
                                    </td>
                                    <td class="table-col">
                                        <span class="sub sub-s2">{{plan.phoneNumber}}</span>
                                    </td>
                                    <td class="table-col">
                                        <span class="sub sub-s2">{{plan.urno}}</span>
                                    </td>
                                    <td class="table-col">
                                        <span class="sub sub-s2 cap">{{plan.day}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row align-items-center pt-2">
                        <div class="col-sm-12">
                            <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" id="DataTables_Table_0_previous"  v-if="pagination.current_page != 1">
                                        <a href="#" @click.prevent="loadMore(pagination.current_page-=2)" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" class="page-link">Prev</a>
                                    </li>
                                    <li class="paginate_button page-item" :class="number == pagination.current_page?'active':''" v-for="(number,index) in pagination.data_length" :key="index">
                                        <a href="javascript:void()" @click="loadMore(index)" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" class="page-link">{{number}}</a>
                                    </li>
                                    <li class="paginate_button page-item next" id="DataTables_Table_0_next" v-if="pagination.current_page < pagination.data_length" >
                                        <a href="javascript:void()" @click.prevent="loadMore(pagination.current_page)" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" class="page-link">
                                            <div>Next</div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import DataTable from "vue-customizable-datatable";
export default {
    data(){
        return {
            plans:[],
            days:['sunday','monday','tuesday','wednesday','thursday','friday','saturday'],
            columns:[
                {label:'',field:'link',html: true,},
                {label:this.$t('customer_name'),field:'customerName',html: false,},
                {label:this.$t('phone_number'),field:'phoneNumber',html: false,},
                {label:this.$t('urno'),field:'urno',html: false,},
                {label:this.$t('day'),field:'day',html: false,},
            ],
            rows:[],
            search:{
                name:'',
                phone:'',
                urno:'',
                day:''
            },
            loaded:false,
            pagination:{
                current_page:1,
                current_data:[],
                data_length:0,
                per_page:10
            },
            subOrders:[]
        }
    },
    methods:{
        searchPlan(){
            this.pagination.current_data = this.plans.filter(plan =>{
                return plan.customerName.toLowerCase().includes(this.search.name.toLowerCase()) 
                        || plan.phoneNumber.includes(this.search.phone)
                        || plan.urno.includes(this.search.urno)
                        || plan.day.toLowerCase().includes(this.search.day.toLowerCase())
            })
            // console.log(this.search.name)
            this.paginateData(this.pagination.current_data)
        },
        loadMore(index){
            this.pagination.current_data = this.subOrders[index]
            this.pagination.current_page = ++index
        },
        paginateData(data){
            this.subOrders=[]
            for (var i=0; i<data.length; i+=this.pagination.per_page) {
                this.subOrders.push(data.slice(i,i+this.pagination.per_page));
            }
            this.pagination.data_length = this.subOrders.length
            this.pagination.current_data = this.subOrders[0]
        },
        getJourneyPlans(){
            this.$store.dispatch('get', `services/app/JourneyPlan/GetAll?WeekdayFilter=-1&AgentId=${this.$store.state.user.agent.id}&MaxResultCount=50`)
            .then((resp)=>{
                // console.log(resp.data.result.items)
                if(resp.data.success){
                    this.plans = resp.data.result.items
                    this.loaded = true
                    if(this.plans.length > 0){
                        this.plans.forEach(element => {
                            element.day = this.days[element.journeyPlan.weekDay]
                            // element.link = "<a href=/journey-plan/"+element.journeyPlan.id +' class="btn btn-light-alt btn-xs btn-icon"><em class="ti ti-eye"></em></a>'
                            // this.rows.push(element)
                        });
                    }
                    this.paginateData(this.plans)
                }
            })
        }
    },
    created(){
        this.getJourneyPlans()
    }
}
</script>

<style scoped>
.data-col input{
    margin-top: 10px;
}
.data-col{
    text-align: center;
    color:#061435;
    padding-top:15px;
    padding-bottom:15px;
}
table{
    border-collapse: separate;
    border-spacing: 0 5px;
}
.card{
    background:#f9f9f9;
}
</style>