<template>
  <div class="page-content">
    <div class="container" v-if="cart.products.length > 0">
      <div class="row">
        <div class="col-sm-8">
          <div class="card content-area">
            <div class="card-innr pl-0 pr-2">
              <div class="card-head pl-3">
                <h4 class="card-title">Order Summary</h4>
              </div>
              <div class="table-responsive">
                <table class="data-table dt-init kyc-list table-striped">
                  <thead>
                    <tr class="data-item data-head">
                      <th class="table-col"></th>
                      <th class="table-col">{{ $t("product") }}</th>
                      <th class="table-col">{{ $t("qty") }}</th>
                      <th class="table-col">{{ $t("unit") }}</th>
                      <th class="table-col">{{ $t("total") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="data-item"
                      v-for="item in cart.products"
                      :key="item.id"
                    >
                      <td class="table-col">
                        <img :src="item.product.imageURL" alt="" />
                      </td>
                      <td class="table-col">
                        <span class="sub sub-s2">{{
                          item.product.productName
                        }}</span>
                      </td>
                      <td class="table-col">
                        <span class="sub sub-s2">{{ item.quantity }}</span>
                      </td>
                      <td class="table-col">
                        <span class="sub sub-s2">{{ item.cartUnitName }}</span>
                      </td>
                      <td class="table-col">
                        <span class="sub sub-s2"
                          >{{ user.country.currency
                          }}{{
                            formatPrice(item.quantity * item.convertedPrice)
                          }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="subtotal d-flex justify-content-end my-4 pt-3">
                <div class="sub-con">
                  <span class="d-block fee-left">{{ $t("subtotal") }}:</span>
                  <span class="d-block fee-left fee-down fee-border"
                    >Delivery fee:</span
                  >
                  <span class="d-block total-left fee-left mt-3"
                    >{{ $t("total") }}:</span
                  >
                </div>

                <div class="sub-con text-right">
                  <span class="d-block"
                    >{{ user.country.currency }}
                    {{ formatPrice(calculateTotal) }}</span
                  >
                  <span class="d-block fee-down fee-border">Free</span>
                  <span class="d-block total-rgt mt-3"
                    >{{ user.country.currency }}
                    {{ formatPrice(calculateTotal) }}</span
                  >
                </div>
              </div>

              <div class="review mt-5 mb-5 pl-2">
                <router-link to="store" class="btn btn-default">{{
                  $t("c_shop")
                }}</router-link>
                <button
                  class="btn btn-primary font-weight-bold"
                  @click="submitOrder()"
                >
                  <span v-if="!loading">PROCEED TO CHECKOUT</span>
                  <span v-else>{{ $t("sub") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form mt-4">
            <form>
              <div class="form-body">
                <h5>1. Contact Information</h5>
                <div class="form-group">
                  <label for="">FULL NAME</label>
                  <input
                    disabled
                    :value="
                      customer.customer.firstName + customer.customer.lastName
                    "
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="">PHONE</label>
                  <input
                    disabled
                    :value="customer.customer.phoneNumber"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-body">
                <h5>2. Delivery Address</h5>
                <div class="form-group row">
                  <div class="col">
                    <label for="">ADDRESS</label>
                    <input
                      disabled
                      :value="customer.customer.outletAddress"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <!-- <div class="form-body">
                                <h5>3.Payment Options</h5>
                                <div class="form-group">
                                    <div class="form-check form-control">
                                        <input class="form-check-input" type="radio" v-model="paymentMethod" id="gridRadios1" value="payOnDelivery" checked>
                                        <label class="form-check-label" for="gridRadios1">
                                            Payment on delivery
                                        </label>
                                    </div>
                                    <div class="form-check form-control mt-2">
                                        <input class="form-check-input" type="radio" v-model="paymentMethod" id="gridRadios2" value="momo">
                                        <label class="form-check-label" for="gridRadios2">
                                            Pay with mobile money
                                        </label>
                                    </div>
                                </div>
                            </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-5" v-else>
      <p class="alert alert-primary">{{ $t("noitem") }}</p>
      <!-- <button class="btn btn-primary" data-toggle="modal" data-target="#momoModalPending">Momo</button> -->
      <router-link to="store" class="btn btn-primary">
        <i class="fa fa-chevron-left mr-2"></i> {{ $t("go_back") }}</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      cart: (context) => context.cart,
      user: (context) => context.user,
    }),
    calculateTotal() {
      let total = 0;
      this.cart.products.forEach((element) => {
        let price = element.quantity * element.convertedPrice;
        total += price;
      });
      return total.toFixed(2);
    },
  },
  data() {
    return {
      loading: false,
      customer: {},
      paymentMethod: "",
    };
  },
  methods: {
    formatPrice(value) {
      var hold = parseFloat(value).toFixed(2);
      return new Intl.NumberFormat().format(hold);
    },
    getCustomer() {
      var data = localStorage.getItem("stockCustomer");
      if (!data) {
        this.$router.push("customers");
      }
      data = decodeURIComponent(data);
      this.customer = JSON.parse(data);
    },
    submitOrder() {
      var orders = [];
      var totalqty = 0;
      this.cart.products.forEach((element) => {
        var item = {};
        item.productUnitPrice = element.convertedPrice;
        item.productTotalPrice = element.quantity * element.convertedPrice;
        item.productQuantity = element.quantity;
        item.productId = element.product.id;
        item.productUnitId = element.prodUnitId;
        item.id = null;
        totalqty += parseFloat(element.quantity);
        orders.push(item);
      });
      var details = {
        customerId: this.customer.customer.id,
        agentId: this.user.agent.id,
        orderTotalPrice: this.calculateTotal,
        orderDate: new Date(),
        orderStatus: 0,
        orderPaymentStatus: 0,
        orderPaymentMethod: 0,
        orderTotalQuantity: totalqty,
        orderDetails: orders,
        tenantId: this.user.country.tenantId,
        orderChannel: 1,
      };
      // console.log(details)
      this.loading = true;
      this.$store
        .dispatch("post", {
          endpoint: "services/app/Orders/CreateOrEdit",
          details: details,
        })
        .then((resp) => {
          // console.log(resp)
          if (resp.data.success) {
            this.loading = false;
            window.SweetAlert(
              "success",
              "Order placed successfully. Order Number: " +
                resp.data.result.orderNumber +
                " Validation code: " +
                resp.data.result.validationCode
            );
            this.cart.products = [];
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  created() {
    this.getCustomer();
  },
};
</script>

<style scoped>
.review {
  display: flex;
  justify-content: space-between;
}
.review .btn-default {
  text-transform: uppercase;
  color: #061435;
  border: 1px solid #061435;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
}
.form-check {
  padding-left: 34px;
}
@media (max-width: 576px) {
  .data-item img {
    width: 47% !important;
  }
  .review {
    flex-direction: column;
    align-items: center;
  }
  .review .btn-default {
    margin-bottom: 10px;
    width: 100%;
  }
  .review button {
    width: 100%;
  }
}
@media (max-width: 340px) {
  .btn {
    padding: 2px 11px;
    font-size: 12px;
  }
}
.btn {
  min-width: auto;
}
.data-item img {
  width: 8%;
}
.data-col {
  padding: 4px 10px 4px 0;
  width: 25%;
}
thead .data-item {
  border-bottom: 1px solid #d2dde9;
}
thead th {
  padding-bottom: 12px !important;
}
.data-head .data-col {
  color: #495463;
}
.c-footer {
  text-align: center;
}
.subtotal {
  border-bottom: 1px solid #e6effb;
  padding-bottom: 12px;
}
.subtotal span {
  font-size: 15px;
  font-weight: bold;
}
.total-left {
  margin-right: 10em;
}
.fee-border {
  border-bottom: 1px solid rgba(6, 20, 53, 0.2);
}
.fee-left {
  font-weight: 400 !important;
  font-family: plus_jakarta_textregular;
}
.fee-down {
  margin-top: 20px;
  padding-bottom: 15px;
}
</style>
