var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card content-area"},[_c('h6',{staticClass:"card-title pl-2 pl-sm-3 pr-2 pr-sm-5 mt-3 mb-0"},[_vm._v(" My Requests ")]),_c('div',{staticClass:"card-innr pt-2"},[(_vm.orders.length > 0)?_c('div',[_c('div',{staticClass:"d-none d-md-block"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"data-table dt-init kyc-list table-striped"},[_c('thead',[_c('tr',{staticClass:"data-item data-head"},[_c('th',{staticClass:"table-col"},[_vm._v(_vm._s(_vm.$t("id")))]),_c('th',{staticClass:"table-col"},[_vm._v(_vm._s(_vm.$t("Date")))]),_c('th',{staticClass:"table-col"},[_vm._v(_vm._s(_vm.$t("agent")))]),_c('th',{staticClass:"table-col"},[_vm._v(_vm._s(_vm.$t("items")))]),_c('th',{staticClass:"table-col dt-status"},[_vm._v(" "+_vm._s(_vm.$t("status"))+" ")]),_c('th',{staticClass:"table-col"},[_vm._v(_vm._s(_vm.$t("action")))])])]),_c('tbody',_vm._l((_vm.orders.slice(0, 5)),function(order){return _c('tr',{key:order.id,staticClass:"data-item"},[_c('td',{staticClass:"table-col"},[_c('span',{staticClass:"sub sub-s2"},[_vm._v(_vm._s(order.orderNumber))])]),_c('td',{staticClass:"table-col"},[_c('span',{staticClass:"sub sub-s2"},[_vm._v(" "+_vm._s(new Date(order.orderDate).toDateString())+" ")])]),_c('td',{staticClass:"table-col"},[_c('span',{staticClass:"sub sub-s2"},[_vm._v(_vm._s(order.customer.firstName + " " + order.customer.lastName))])]),_vm._m(0,true),_c('td',{staticClass:"table-col"},[_c('div',{staticClass:"table-flex"},[_c('div',{staticClass:"dot",class:order.orderStatus == 0
                                  ? 'dot-warning'
                                  : order.orderStatus == 1
                                  ? 'dot-success'
                                  : 'dot-danger'}),_c('span',{staticClass:"sub sub-s2",class:order.orderStatus == 0
                                  ? 'dot-t-warning'
                                  : order.orderStatus == 1
                                  ? 'dot-t-success'
                                  : 'dot-t-danger'},[_vm._v(_vm._s(order.orderStatus == 0 ? _vm.$t("pending") : order.orderStatus == 1 ? _vm.$t("approaved") : _vm.$t("cancelled")))])])]),_c('td',{staticClass:"table-col text-center table-action"},[_c('router-link',{staticClass:"eye",attrs:{"to":'/requisition/view/' + order.id}},[_c('em',{staticClass:"ti ti-eye"})])],1)])}),0)])])]),_c('div',{staticClass:"m-recent-orders d-md-none d-block"},_vm._l((_vm.orders.slice(0, 5)),function(order){return _c('div',{key:order.id,staticClass:"mro-item"},[_c('router-link',{staticClass:"d-flex justify-content-between",attrs:{"to":'/requisition/view/' + order.id}},[_c('div',{staticClass:"mro-left"},[_c('div',{staticClass:"mrol-wrapper d-flex"},[_c('div',{staticClass:"mrol-icon",class:order.orderStatus == 1 ? 'success' : 'pending'},[_c('i',{staticClass:"fa fa-arrow-up"})]),_c('div',{staticClass:"mrol-main ml-2"},[_c('p',[_vm._v(_vm._s(order.customer.firstName))]),_c('span',{staticClass:"order-id"},[_vm._v("ORDER ID: "+_vm._s(order.orderNumber))])])])]),_c('div',{staticClass:"mro-right text-right"},[_c('span',[_vm._v(_vm._s(order.orderStatus == 0 ? _vm.$t("pending") : order.orderStatus == 1 ? "approaved" : _vm.$t("cancelled")))])])])],1)}),0)]):_c('div',{staticClass:"text-center text-dark font-weight-bold alert alert-info"},[_c('p',[_vm._v("No recent orders")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"sub sub-s2"},[_vm._v("2")])])
}]

export { render, staticRenderFns }