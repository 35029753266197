import Vue from "vue";
import Vuex from "vuex";

import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    endpoint: process.env.VUE_APP_ENDPOINT,
    endpoint_baseurl: process.env.VUE_APP_ENDPOINT_BASE_URL,
    requisitions: [],
    requisition: {},
    notification: {
      type: 0,
      message: "",
    },
    user: null,
    token: "",
    customer: null,
    cart: {
      products: [],
    },
    language: "",
  },
  getters,
  mutations,
  actions,
});

export default store;
