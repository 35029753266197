export default {
  approved: "Approved",
  t_line_items: "Total Line Items",
  confirm_o: "Confirm Order",
  confirm_delivery: "Confirm Delivery",
  territory: "Territory",
  profile: "Profile",
  c_password: "Change Password",
  o_password: "Current Password",
  n_password: "New Password",
  cn_password: "Confirm New Password",
  stock_r: "Stock Requisition",
  requestor: "Requestor",
  requisitions: "Requisitions",
  newsfeed: "Feeds",
  requisition: "Requisition",
  create_request: "Create Request",
  request_summary: "Request Summary",
  request_details: "Request Details",
  n_items: "Items",
  order_val: "Order validation",
  order_no: "Order number",
  summary: "Order summary",
  edit: "Edit",
  back: "Back",
  action: "Action",
  up_order: "Update order",
  e_order: "Edit Order",
  add_new_prod: "Add new product",
  products: "Products",
  quantity: "Quantity",
  add_prod: "Add Product",
  fname: "First name",
  lname: "Last name",
  email: "Email",
  outlet_name: "Outlet name",
  update: "Update",
  journey_plan: "Journey Plan",
  customer_name: "Customer name",
  phone_number: "Phone Number",
  urno: "URNO",
  day: "Day",
  validated: "Validated",
  order_n: "Order number",
  order_id: "Order ID",
  login: "Login",
  loading: "Loading...",
  username: "Username",
  password: "Password",
  cpsw: "Change Password",
  orders: "Orders",
  dashboard: "Dashboard",
  customers: "Customers",
  customer: "Customer",
  welcome: "Welcome!",
  logout: "Logout",
  new_order: "New Order",
  pending: "Pending",
  p_orders: "Pending Orders",
  p_order: "Pending Order",
  ds_order: "Delivered Order",
  cs_order: "Cancelled Order",
  d_status: "Delivery Status",
  c_status: "Confirmation Status",
  received: "Received",
  NotRecieved: "Not Recieved",
  dd_orders: "Delivered Orders",
  r_orders: "Recent Orders",
  place_order: "Place Order",
  v_all: "View All",
  id: "ID",
  client: "client",
  price: "Price",
  time: "time",
  status: "Status",
  name: "name",
  phone: "phone",
  f_txt1:
    "For Trade Purposes. Not for Circulation or Distribution to the General Public.",
  f_c: "TRADERPADi - All rights reserved.",
  payment: "payment",
  delivered: "Delivered",
  cancelled: "Cancelled",
  o_details: "Order Details",
  o_status: "Order Status",
  o_pstats: "Order Payment Status",
  p_status: "Payment Status",
  p_method: "Payment Method",
  to_quant: "Total Order Quantity",
  o_date: "Order Date",
  total: "Total",
  s_n: "S/N",
  product: "product",
  unit: "Unit",
  uantit: "uantit",
  d_order: "Deliver Order",
  c_order: "Cancel Order",
  print: "print",
  search: "search",
  history: "history",
  address: "address",
  direction: "direction",
  channel: "Channel",
  o_channel: "Order Channel",
  o_for: "Order for",
  cash: "cash",
  credit: "credit",
  pos: "pos",
  transfer: "transfer",
  subtotal: "Subtotal",
  order: "Order",
  paid: "Paid",
  checkout: "Checkout",
  go_back: "Go Back",
  noitem: "No item in your cart",
  sub: "Submitting...",
  s_order: "Submit Order",
  c_shop: "Continue Shopping",
  r_order: "Review Order",
  l_point: "Loyalty Point",
};
