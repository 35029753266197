<template>
  <div class="page-content c-requi">
    <div class="container">
      <div class="card content-area">
        <div class="card-innr">
          <div class="editMode">
            <div class="gaps-1-5x"></div>
            <div class="data-details pt-2 pb-0 px-0">
              <form>
                <div class="">
                  <div class="px-3">
                    <div class="form-group">
                      <label>{{ $t("products") }}</label>
                      <v-select
                        v-model="newOrder.product"
                        :options="products"
                        label="productName"
                        @input="getproductPrices()"
                      />
                    </div>
                  </div>
                  <div
                    v-if="getProductsLoading"
                    class="loading-div"
                    style="
                      position: absolute;
                      width: 100%;
                      text-align: center;
                      margin-top: 80px;
                    "
                  >
                    <h5
                      style="color: #112a63; font-weight: bold; font-size: 25px"
                    >
                      Loading...
                    </h5>
                  </div>
                  <div :class="step != 2 ? 'disabled-div' : ''">
                    <div class="row mt-3">
                      <div class="col-sm-6">
                        <div class="pprices px-3">
                          <div class="form-group">
                            <label>Select Product Price</label>
                            <select
                              style=""
                              name=""
                              v-model="newOrder.productPrice"
                              class="form-control"
                              v-if="
                                productPrices &&
                                productPrices.productPrices.length > 0
                              "
                            >
                              <option
                                v-for="(
                                  price, index
                                ) in productPrices.productPrices"
                                :key="index"
                                :value="price"
                              >
                                {{ price.productUnitName }} ({{
                                  user.country.currency
                                }}{{ price.productPrice.unitPrice }})
                              </option>
                            </select>
                            <select v-else class="form-control" id=""></select>
                          </div>
                          <!-- <div
                          class="form-group"
                          v-for="(price, index) in productPrices"
                          :key="index"
                        >
                          <label class="d-block" for="">{{ price.name }}</label>
                          <div v-if="price.productPrices.length > 0">
                            <div
                              class="form-check form-check-inline mr-4"
                              v-for="(ld, index2) in price.productPrices"
                              :key="index2"
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                :id="'prices' + index + '33' + index2"
                                v-model="newOrder.productPrice"
                                :value="ld"
                                @change="setProductPrice()"
                              />
                              <label
                                class="form-check-label"
                                :for="'prices' + index + '_' + index2"
                                >{{ ld.productUnitName }} (
                                {{ user.country.currency
                                }}{{ ld.productPrice.unitPrice }})</label
                              >
                            </div>
                          </div>
                          <small v-else class="alert alert-danger py-2"
                            >No price available</small
                          >
                        </div> -->
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="px-3 pb-3">
                          <div class="form-group">
                            <label>{{ $t("quantity") }}</label>
                            <input
                              type="number"
                              step=".01"
                              v-model="newOrder.quantity"
                              min="1"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="px-3 pb-3">
                          <button
                            class="btn btn-info mt-2"
                            @click.prevent="addProduct()"
                          >
                            {{ $t("add_prod") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="gaps-3x"></div>
            <div class="card content-area order-summary">
              <div class="card-innr px-0 pt-1">
                <h5
                  class="card-sub-title mb-4 pl-3 font-weight-bold"
                  style="letter-spacing: 1px; font-size: 15px"
                >
                  {{ $t("request_summary") }}
                </h5>
                <div class="table-responsive" v-if="order.length > 0">
                  <table class="data-table dt-init kyc-list table-striped">
                    <thead>
                      <tr class="data-item data-head">
                        <th class="data-col dt-user">{{ $t("s_n") }}</th>
                        <th class="data-col">{{ $t("product") }}</th>
                        <th class="data-col">Product Price</th>
                        <th class="data-col">{{ $t("unit") }}</th>
                        <th class="data-col">Amount</th>

                        <th class="data-col">{{ $t("qty") }}</th>
                        <th class="data-col">{{ $t("total") }}</th>
                        <th class="data-col">{{ $t("action") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="data-item"
                        v-for="(ord, index) in order"
                        :key="index"
                      >
                        <td class="data-col dt-user">
                          <span class="sub sub-s2">{{ ++index }}</span>
                        </td>
                        <td class="data-col">
                          <span class="sub sub-s2">{{ ord.productName }}</span>
                        </td>
                        <td class="data-col">
                          <span class="sub sub-s2">{{
                            ord.productPrice.priceListName
                          }}</span>
                        </td>
                        <td class="data-col">
                          <span class="sub sub-s2">{{
                            ord.productPrice.productUnitName
                          }}</span>
                        </td>
                        <td class="data-col">
                          <span class="sub sub-s2"
                            >{{ user.country.currency
                            }}{{
                              new Intl.NumberFormat().format(
                                ord.productPrice.productPrice.unitPrice
                              )
                            }}</span
                          >
                        </td>
                        <td class="data-col">
                          <span class="sub sub-s2">{{
                            ord.quantityRequested
                          }}</span>
                        </td>
                        <td class="data-col">
                          <span class="sub sub-s2">{{
                            new Intl.NumberFormat().format(
                              ord.productPrice.productPrice.unitPrice *
                                ord.quantityRequested
                            )
                          }}</span>
                        </td>
                        <td class="data-col">
                          <button
                            class="btn btn-danger py-0 px-2"
                            @click="deleteOrder(ord.productId)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="cal-total d-flex justify-content-end">
                    <h4 class="btn btn-primary" style="font-size: 15px">
                      {{ $t("total") }}: {{ user.country.currency }}
                      {{ new Intl.NumberFormat().format(calculateTotal) }}
                    </h4>
                  </div>
                  <div
                    class="subtotal d-block d-sm-flex justify-content-between align-items-center py-3 px-3"
                  >
                    <div class="sub-con">
                      <button
                        @click="createRequest()"
                        class="btn btn-info py-2"
                        style="font-size: 17px"
                        :disabled="loading"
                      >
                        <span v-if="!loading">{{ $t("create_request") }} </span>
                        <span v-else>{{ $t("loading") }}</span>
                      </button>
                    </div>
                    <!-- <div class="sub-con mt-3 mt-sm-0">
                      <span class="pr-5">{{ $t("subtotal") }}:</span>
                      <span class="badge btn-primary ucap total-amt or-kil"
                        >{{ user.country.currency }}
                        {{ formatPrice(calculateTotal) }}</span
                      >
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState({
        user: (context) => context.user,
        calculateTotal() {
          let total = 0;
          this.order.forEach((element) => {
            let price =
              element.productPrice.productPrice.unitPrice *
              element.quantityRequested;
            total += price;
          });
          return total.toFixed(2);
        },
      }),
    },
    data() {
      return {
        getProductsLoading: false,
        newOrder: {
          quantity: 1,
        },
        products: [],
        rawProducts: [],
        units: [],
        order: [],
        loading: false,
        selected: true,
        step: 1,
        productPrices: null,
        productPrice: {},
      };
    },
    methods: {
      setProductPrice() {
        console.log(this.newOrder.productPrice);
        this.$forceUpdate();
        if (this.newOrder.productPrice) {
          console.log("ff");
        }
      },
      createRequest() {
        this.loading = true;

        console.log(this.order);
        this.$store
          .dispatch("post", {
            endpoint: "services/app/StockRequests/Submit",
            details: {
              requestedById: this.user.agent.id,
              stockRequestDetails: this.order,
            },
          })
          .then((resp) => {
            this.loading = false;
            if (resp.data.success) {
              this.$router.push({ name: "stockRequests" });
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      async next() {
        console.log("ll");
        if (this.step == 2) {
          if (!this.newOrder.productPrice) {
            window.ToasterAlert("error", "Product price is required");
            return false;
          }
          this.step = 3;
          console.log(this.newOrder.prodUnitId);
        }
      },

      deleteOrder(id) {
        console.log(id);
        this.order.forEach((item, index) => {
          if (item.productId == id) {
            this.order.splice(index, 1);
          }
        });
        console.log(this.products);
        var sProd = this.rawProducts.filter(
          (product) => product.product.id == id
        )[0];
        this.products.push(sProd.product);
      },
      updateProductUnitId(event, productId) {
        this.order.forEach((order) => {
          if (order.productId == productId) {
            order.productUnitId = event.target.value;
            order.productUnitName = this.units.filter(
              (unit) => unit.id == event.target.value
            )[0].productUnitName;
          }
        });
        console.log(this.order);
      },
      getUnits() {
        this.$store
          .dispatch("getWithTalent", "services/app/ProductUnits/GetAll")
          .then((resp) => {
            this.units = resp.data.result.items.map((unit) => unit.productUnit);
          });
      },
      async getproductPrices() {
        this.step = 1;
        this.newOrder.productPrice = null;
        this.getProductsLoading = true;
        await this.$store
          .dispatch(
            "get",
            `services/app/ProductPrice/GetAllForProduct?productId=${this.newOrder.product.id}`
          )
          .then((resp) => {
            this.getProductsLoading = false;
            this.step = 2;
            // show only product prices for retailers not for wholesaler
            this.productPrices = resp.data.result.find(
              (item) => item.name == "Retail Price"
            );
            console.log("dd", this.productPrices);
          })
          .catch(() => {
            this.loading = false;
          });
      },
      getProducts() {
        this.$store
          .dispatch(
            "getProduct",
            "services/app/Products/GetAll?MaxResultCount=1000"
          )
          .then((resp) => {
            if (resp.data.success) {
              this.rawProducts = resp.data.result.items;
              this.products = resp.data.result.items.map(
                (product) => product.product
              );

              console.log(this.products);
            }
          });
      },
      addProduct() {
        if (this.newOrder.quantity < 1) {
          window.ToasterAlert("error", "Invalid quantity");
          return false;
        }
        // if (!this.newOrder.prodUnitId) {
        //   window.ToasterAlert("error", "Unit is required");
        //   return false;
        // }
        // if (!this.newOrder.product) {
        //   window.ToasterAlert("error", "Product is required");
        //   return false;
        // }
        var newProd = {};
        newProd.productName = this.newOrder.product.productName;
        newProd.quantityRequested = this.newOrder.quantity;
        newProd.productId = this.newOrder.product.id;
        newProd.productUnitId =
          this.newOrder.productPrice.productPrice.productUnitId;
        newProd.productPrice = this.newOrder.productPrice;
        newProd.unitPrice = this.newOrder.productPrice.productPrice.unitPrice;
        this.order.push(newProd);
        this.removeProductFromProducts();
        this.newOrder.product = "";
        this.newOrder.productPrice = null;
        this.newOrder.quantity = 1;
        this.step = 1;
        this.$forceUpdate();
        console.log(this.order);
      },
      removeProductFromProducts() {
        this.order.forEach((item) => {
          this.products.forEach((prod) => {
            if (prod.id == item.productId) {
              this.products.splice(
                this.products.findIndex((p) => p.id == item.productId),
                1
              );
            }
          });
        });
      },
    },
    created() {
      this.getProducts();
      this.getUnits();
    },
  };
</script>

<style>
  .c-requi .vs__dropdown-toggle {
    height: 37px;
    font-size: 13px;
  }
</style>
<style scoped>
  .btn-info {
    font-size: 13px;
  }
  .disabled-div {
    pointer-events: none;
    background-color: #80808063;
  }
  .disabled-div .form-control {
    background-color: #80808063;
  }
  .form-control {
    font-size: 13px;
    height: 37px !important;
  }
  .disabled-div .btn {
    opacity: 0.4;
  }
  label {
    font-size: 14px;
  }
  h5 span {
    color: black;
  }
  .form-group {
    margin-bottom: 0;
  }
  .editMode .data-col {
    text-align: center;
  }
  .data-head .data-col {
    color: #495463;
    width: 20%;
  }
  .btn-info {
    background: #00738c;
    border: none;
  }
  .btn {
    min-width: 0;
  }
  @media (max-width: 576px) {
    .form-group {
      margin-bottom: 10px;
    }
  }
</style>
