<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    var currentLanguage = window.localStorage.getItem("language");
    if (currentLanguage == "undefined") {
      currentLanguage = "en";
    }
    this.$store.commit("setLang", currentLanguage);
  },
};
</script>

<style></style>
