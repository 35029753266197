<template>
  <div :class="`toast-add-items fixed-to-width ${data.active ? 'active' : ''}`">
    <div
      class="toast-wrapper d-flex justify-content-between align-items-center"
    >
      <div class="toast-message item pl-0">{{data.qty}} {{data.qty > 1 ? 'items' : 'item'}}</div>
      <div class="toast-message">
        <router-link to="/checkout" class="btn order-link font-14">{{data.edit?'Edit Order':'View order'}}</router-link>
      </div>
      <div class="toast-message message-details">
        <h5 class="mb-0">
          <span>{{$store.state.user.country.currencySymbol}} {{data.price}}</span>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    data: {},
  },
  data(){
    return {
      summary:{
        qty:0,
        price:0
      }
    }
  },
  computed: {
    ...mapState({
      cart: (context) => context.cart,
    }),
    calQty(){
      let qty = 0
      if(this.cart.products > 0){
        qty = this.cart.products.reduce( (total, num)=>{
          return parseFloat(total) + parseFloat(num.quantity)
        } , 0)
      }
      return qty
    },
    calTotalPrice(){
      let price = 0
      if(this.cart.products > 0){
        price = this.cart.products.reduce( (total, num)=>{
          return this.formatPrice(parseFloat(total) + (parseFloat(num.convertedPrice) * parseFloat(num.quantity)))
        } , 0)
      }
      return price;
    }
  },
  methods:{
  }
};
</script>
<style scoped>
.order-link{
    min-width: 0;
}
.message-details span {
    color: #fff;
}
.fixed-to-width {
  position: fixed;
  max-width: 767px;
}
.toast-add-items {
  border-radius: 2px;
  width: 80%;
  background-color: #099451;
  z-index: 200;
  bottom: 0;
  padding: 14px;
  color: #fff;
  visibility: hidden;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  -webkit-transition: bottom 300ms ease;
  transition: bottom 300ms ease;
}
.toast-add-items.active {
  bottom: 90px;
  visibility: visible;
  pointer-events: visible;
}
.toast-add-items a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  border: none;
}
</style>