import axios from "axios";
import router from "../router";

export default {
  handleError(context, error) {
    console.log(error);
    if (error.request.status == 422) {
      var resp = JSON.parse(error.request.response);
      window.ToasterAlert("error", resp.error.message);
      // window.ToasterAlert('error', msg)
      // return msg;
    } else if (error.request.status == 500) {
      resp = JSON.parse(error.request.response);
      window.ToasterAlert("error", resp.error.message);
    } else if (error.request.status == 404) {
      resp = JSON.parse(error.request.response);
      var msg = "Request not found";
      window.ToasterAlert("error", msg);
    } else if (error.request.status == 400) {
      resp = JSON.parse(error.request.response);
      window.ToasterAlert("error", resp.error.message);
    } else if (error.request.status == 401) {
      var res = JSON.parse(error.request.response);
      window.ToasterAlert("error", res.error.message);
      context.commit("logout");
    } else if (error.request.status == 403) {
      var res2 = JSON.parse(error.request.response);
      window.ToasterAlert("error", res2.error.message);
      router.back();
      //   context.commit("logout");
    } else {
      msg = "Oops! server error, Please try again";
      window.ToasterAlert("error", msg);
    }
  },
  post(context, data) {
    // console.log(data)
    return new Promise((resolve, reject) => {
      axios
        .post(context.state.endpoint + data.endpoint, data.details, {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + context.state.token,
            "Abp.TenantId": data.details.tenantId,
          },
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("handleError", error);
          reject(error);
        });
    });
  },
  get(context, endpoint) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.state.endpoint + endpoint, {
          headers: {
            Authorization: "Bearer " + context.state.token,
            // "Abp.TenantId":context.state.user.country.tenantId,
            // 'Access-Control-Allow-Origin':'*',
            // "accept":'application/json',
            "content-type": "application/json",
          },
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("handleError", error);
          reject(error);
        });
    });
  },
  getWithTalent(context, endpoint) {
    // console.log(context.state.user.country.tenantId)
    return new Promise((resolve, reject) => {
      axios
        .get(context.state.endpoint + endpoint, {
          headers: {
            Authorization: "Bearer " + context.state.token,
            "Abp.TenantId": context.state.user.country.tenantId,
            // 'Access-Control-Allow-Origin':'*',
            accept: "application/json",
            "content-type": "application/json",
          },
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("handleError", error);
          reject(error);
        });
    });
  },
  getProduct(context, endpoint) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.state.endpoint + endpoint, {
          headers: {
            Authorization: "Bearer " + context.state.token,
            "Abp.TenantId": context.state.user.country.tenantId,
            // 'Access-Control-Allow-Origin':'*',
            // "accept":'application/json',
            "content-type": "application/json",
          },
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("handleError", error);
          reject(error);
        });
    });
  },
};
